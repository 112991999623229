import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { Navigate, useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { IReponse } from "interfaces/definition";
import { InferType } from "yup";
import { Footer } from "components";
import { SignUpSchema } from "schemas/formSchema";
import { useAppDispatch } from "store";
import { signUp } from "features/auth/authThunks";
import kuvadi from "../../assets/img/kuvadi.png";




export type ActivateParams = {
  token: string;
};
type User = InferType<typeof SignUpSchema>;

const ActivationAccount = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams<ActivateParams>();
  const navigate = useNavigate();

  if (token) {
    <Navigate to="/notFound" />;
  }


  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<User>({
    defaultValues: {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      token: token!,
    },
    resolver: yupResolver(SignUpSchema),
  });

  const onSubmit = (data) => {
    if (token) {
      // eslint-disable-next-line no-param-reassign
      dispatch(signUp(data))
        .then((res) => {
          const respuestaAxios = res.payload as AxiosResponse<any, any> | AxiosError<any>;
          console.log(res)

          if (respuestaAxios instanceof AxiosError) {
            if (respuestaAxios.response?.status !== 200) {
              toast.error(respuestaAxios.message);
            }
          } else {

            toast.success(respuestaAxios.data.message);
            navigate("/");

          }
        })
        .catch((error) => {
          const { message } = error.response.data as IReponse;
          toast.error(message);
        });
    }
  };

  return (
    <div className="container is-fluid p-0 kvd-bg">
      <div className="content is-flex is-flex-direction-column m-auto px-6">
        <div className="section">
          <img
            className="d-block mx-auto mb-4"
            src={kuvadi}
            alt=""
            width="512"
          />
          <h1 className="title">Bienvenido</h1>
          <p className="has-text-weight-normal is-size-5 ">
            Gracias por confiar en nosotros. Estás a punto de comenzar a
            disfrutar de todo lo que tenemos para ofrecerte. Una vez que
            registres tus datos como primer usuario, podrás ingresar y explorar
            todo lo que tenemos disponible para ti.
          </p>
        </div>
        <form className="box my-5"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="field is-horizontal">
            <div style={{ display: "none" }}>
              <input
                type="text"
                id="token"
                required
                value={token}
                {...register("token")}
              />
            </div>
            <div className="field-label is-normal">
              <label className="label">Nombres y apellidos</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    type="text"
                    className="input"
                    placeholder="nombres"
                    id="firstName"
                    {...register("firstName")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </p>
                {errors.firstName && (
                  <p className="help is-danger">
                    {`${errors.firstName?.message}`}
                  </p>)
                }
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left has-icons-right">
                  <input
                    type="text"
                    className="input"
                    placeholder="apellidos"
                    id="lastName"
                    {...register("lastName")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </p>
                {errors.lastName && (
                  <p className="help is-danger">
                    {`${errors.lastName?.message}`}
                  </p>)
                }
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Email</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    type="email"
                    className="input"
                    id="email"
                    placeholder="you@example.com"
                    {...register("email")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-regular fa-envelope" />
                  </span>
                </p>

                {errors.email ? (
                  <p className="help is-danger">
                    {`${errors.email.message}`}
                  </p>) : (<p className="help">este correo sera el usuario de la cuenta.</p>)
                }
              </div>
            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Datos de Contacto</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left has-icons-right">
                  <input
                    type="tel"
                    className="input"
                    placeholder="Numero de contacto (opcional)"
                    id="phone"
                    {...register("phone")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-mobile-screen" />
                  </span>
                </p>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    type="text"
                    className="input"
                    id="address"
                    placeholder="dirección (opcional)"
                    required
                    {...register("address")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-location-dot" />
                  </span>
                </p>
              </div>

            </div>
          </div>
          <div className="field is-horizontal">
            <div className="field-label is-normal">
              <label className="label">Contraseña</label>
            </div>
            <div className="field-body">
              <div className="field">
                <p className="control is-expanded has-icons-left">
                  <input
                    type="password"
                    className="input"
                    id="password"
                    {...register("password")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-key" />
                  </span>
                </p>
                <p className="help"> {errors.password && errors.password.message?.toString()}</p>
              </div>
              <div className="field">
                <p className="control is-expanded has-icons-left has-icons-right">
                  <input
                    type="password"
                    className="input"
                    id="repeatPassword"
                    {...register("repeatPassword")}
                  />
                  <span className="icon is-small is-left">
                    <i className="fa-solid fa-key" />
                  </span>
                </p>
                <p className="help">
                  {errors.repeatPassword &&
                    errors.repeatPassword.message?.toString()}
                </p>
              </div>
            </div>
          </div>
          <div className="level">
            <div className="level-left" />
            <div className="level-right">
              <div className="level-item">
                <button className="button is-link" type="submit">
                  Registrarse
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default ActivationAccount;
