import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client/core';
import { store } from 'store';

const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/V1/ql`,
});


const authMiddleware = new ApolloLink((operation, forward) => {
    const { accessToken } = store.getState().auth;

    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            Authorization: accessToken ? `Bearer ${accessToken}` : '',
        },
    }));
    return forward(operation);
});

const client = new ApolloClient({
    link: authMiddleware.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;