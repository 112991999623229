import React, { useState, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import Camera, { DEVICE, FACING_MODE, PLACEMENT } from 'react-camera-ios';
import { AppsRegisterClient } from 'schemas/formSchema';
import { InferType } from 'yup';
import 'react-camera-ios/build/styles.css';

type SendInput = InferType<typeof AppsRegisterClient>;

interface Step1Props {
    formData: SendInput;
    setFormData: (data: any) => void;
    nextStep: () => void;
}

const Step1: React.FC<Step1Props> = ({ formData, setFormData, nextStep }) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<SendInput>({
        defaultValues: formData,
    });
    const [capturedImageData, setCapturedImageData] = useState<string>();
    const [isCameraOpen, setCameraOpen] = useState<boolean>(false)

    const containerStyle = { display: 'flex', height: '500px', width: '100%' };

    const onSubmit: SubmitHandler<SendInput> = data => {
        setFormData({ ...formData, ...data });
        nextStep();
    };

    const handlePictureTaken = (imageData: string) => {
        setCapturedImageData(imageData);
        setValue("photo", imageData)
        setCameraOpen(!isCameraOpen);
    };

    useEffect(() => {
        setCapturedImageData(formData.photo)
    }, [formData])


    return (
        <div className="column right has-text-centered">
            <h1 className="title is-4">Terminos y condiciones</h1>
            <p className="description is-5 has-text-black">Quieres hacer parte de kuvadi, de marketplace? Aceptando términos y condiciones nosotros y otras marcas de este sector te podemos enviar excelentes ofertas, vía e-mail y SMS</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" value="1" {...register('agree', { required: true })} />
                            Acepto los <a href="https://kuovadi-assets.s3.amazonaws.com/privacidad-Conozca+S.A.S.-Kuvadi.pdf" target='_blank' rel='noreferrer'>terminos y condiciones</a>
                        </label>
                    </div>
                </div>

                <input type="text" style={{ display: "none" }} {...register('photo', { required: "no se ha registrado un documento." })} />


                <div className="box">
                    <div className="notification is-success is-light">
                        <p className="description has-text-centered has-text-grey-dark">Por favor, sube una fotografía clara de un documento válido o de ti mismo/a. Esta foto servirá para vericar tu identidad, así como de tu aceptación de los términos y condiciones del servicio. ¡Gracias!</p>
                    </div>
                    {errors.photo &&
                        <div className="notification is-danger is-light">
                            <p className="help is-danger">
                                {errors.photo?.message}
                            </p>
                        </div>
                    }

                    {!isCameraOpen &&
                        <button type='button' className="button is-medium" onClick={() => setCameraOpen(!isCameraOpen)}>
                            <span className="icon">
                                <i className="fa-solid fa-camera fa-2x" />
                            </span>
                        </button>}

                    {isCameraOpen &&
                        <div style={containerStyle}>
                            <Camera
                                device={DEVICE.MOBILE}
                                facingMode={FACING_MODE.ENVIRONMENT}
                                placement={PLACEMENT.COVER}
                                quality="1"
                                onError={error => console.log(error)}
                                onTakePhoto={dataUrl => handlePictureTaken(dataUrl)}
                            />
                        </div>
                    }

                    {capturedImageData && (
                        <div>
                            <h3>Imagen a cargar:</h3>
                            <img src={capturedImageData} alt="Captured" />
                        </div>
                    )}
                </div>
                <button type='submit' className="button is-block is-info is-fullwidth is-medium is-rounded">Aceptar</button>
            </form>
        </div>
    );
};

export default Step1;
