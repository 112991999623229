import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { logout, setCredentials } from 'features/auth/authSlice';
import { IReponse, LoginCredentials, Newsletter } from 'interfaces/definition';
import { RootState } from 'store';

const baseQuery = fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/V1`,
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const state = getState() as RootState;
        const token = state.auth.accessToken
        if (token) {
            headers.set("authorization", `Bearer ${token}`)
        }
        return headers
    }
})

const baseQueryWithReauth = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 403) {
        const resfreshResult = await baseQuery('/refresh-token', api, extraOptions)
        if (resfreshResult?.data) {
            console.log(resfreshResult)
            const { content } = resfreshResult?.data as IReponse;
            const { user, id, signature } = api.getState().auth;

            const update = {
                accessToken: content,
                user,
                id,
                signature
            }
            api.dispatch(setCredentials(update))
        } else {
            api.dispatch(logout())
        }
    }

    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: builder => ({
        login: builder.mutation({
            query: (credentials: LoginCredentials) => ({
                url: 'login',
                method: 'POST',
                body: credentials
            })
        }),
        SaveNewsletter: builder.mutation({
            query: (newsletter: Newsletter) => ({
                url: 'template',
                method: 'POST',
                body: newsletter
            })
        }),
        RemoveNewsletter: builder.mutation({
            query: (newsletterId: string) => ({
                url: `template/${newsletterId}`,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useLoginMutation,
    useSaveNewsletterMutation,
    useRemoveNewsletterMutation,
} = apiSlice