import { useState } from "react";
import { Link } from "react-router-dom";
import kuvadi from "../../assets/img/kuvadi-white.png";

const SideBar = (): JSX.Element => {

  const [activeMenu, setActiveMenu] = useState(null);

  const handleDropdownClick = (e, menuId) => {
    e.preventDefault();
    setActiveMenu((prevMenu) => (prevMenu === menuId ? null : menuId));
  };

 
  return (
    <aside className="aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label">
          <span>
            <img
              className="mx-3 py-2"
              src={kuvadi}
              alt="kuovadi_logo"
              width={128}
            />
          </span>
        </div>
      </div>
      <div className="menu is-menu-main">
        <p className="menu-label">General</p>
        <ul className="menu-list">
          <li>
            <Link to="/admin" className="is-active router-link-active has-icon">
              <span className="icon">
                <i className="fa-solid fa-gauge" />
              </span>
              <span className="menu-item-label">Panel de Control</span>
            </Link>
          </li>
        </ul>
        <p className="menu-label">TARGETS AUTOMATIZADOS</p>
        <ul className="menu-list">
          <li className={`${activeMenu === 'campaign' ? 'is-active' : ''}`} >
          <a
            href="/#"
            className="has-icon has-dropdown-icon"
            onClick={(e) => handleDropdownClick(e, 'campaign')}
          >
              <span className="icon">
                <i className="mdi fa-regular fa-calendar" />
              </span>
              <span className="menu-item-label">Campañas</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i className="mdi fa-solid fa-chevron-right" />
                </span>
              </div>
            </a>
            <ul>
              <li>
                <Link to="/admin/campaign">Crear Campaña</Link>
              </li>
              <li>
                <Link to="/admin/campaign/all">Campañas</Link>
              </li>
              <li>
                <Link to="/admin/campaigns/add-send">Agregar perfil</Link>
              </li>
            </ul>
          </li>
          <li className={`${activeMenu === 'send' ? 'is-active' : ''}`}>
            <a href="/#" className="has-icon has-dropdown-icon"  onClick={(e) => handleDropdownClick(e, 'send')}>
              <span className="icon">
                <i className="mdi fa-solid fa-envelopes-bulk" />
              </span>
              <span className="menu-item-label">Envios</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i className="mdi fa-solid fa-chevron-right" />
                </span>
              </div>
            </a>
            <ul>
              <li>
                <Link to="/admin/newsletter">Newsletters</Link>
              </li>
              <li>
                <Link to="/admin/sends/schedule">Programar Envios</Link>
              </li>
              <li>
                <Link to="/admin/sends/scheduled-list">Programaciones</Link>
              </li>
            </ul>
          </li>
          <li className={`${activeMenu === 'report' ? 'is-active' : ''}`}>
            <a href="/#" className="has-icon has-dropdown-icon"  onClick={(e) => handleDropdownClick(e, 'report')}>
              <span className="icon">
                <i className="mdi fa-solid fa-chart-pie" />
              </span>
              <span className="menu-item-label">Reportes</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i className="mdi fa-solid fa-chevron-right" />
                </span>
              </div>
            </a>
            <ul>
              <li>
                <Link to="/admin/">Campañas Activas</Link>
              </li>
              <li>
                <Link to="/admin/" >Capitalización y metas</Link>
              </li>
              <li>
                <Link to="/admin/" >Cuantificaciones</Link>
              </li>
              <li>
                <Link to="/admin/">
                  Category Management
                </Link>
              </li>
              <li>
                <Link to="/admin/" >
                  Evaluación Campañas
                </Link>
              </li>
              <li>
                <Link to="/admin/" >
                  Asociaciones
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${activeMenu === 'setup' ? 'is-active' : ''}`}>
            <a href="#/" className="has-icon has-dropdown-icon" onClick={(e) => handleDropdownClick(e, 'setup')}>
              <span className="icon">
                <i className="mdi fa-solid fa-gears" />
              </span>
              <span className="menu-item-label">Configuraciones</span>
              <div className="dropdown-icon">
                <span className="icon">
                  <i className="mdi fa-solid fa-chevron-right" />
                </span>
              </div>
            </a>
            <ul>
              <li>
                <Link to="/admin/new-user">Nuevo Usuario</Link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default SideBar;
