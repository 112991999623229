import { ResetPassword } from "interfaces/definition";
import {api} from "./httpCommon";

export async function forgot(forgotParams: { email: string }) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.post("forgot-password", forgotParams);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function logOut() {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.post("logout");
    return response;
  } catch (error) {
    throw error;
  }
}

export async function changuePassword(resetData: ResetPassword) {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.post("reset-password", resetData);
    return response;
  } catch (error) {
    throw error;
  }
}

