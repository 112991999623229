import { Footer } from "components";
import { InferType, object, ref, string } from "yup";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "store";
import { resetPassword } from "features/auth/authThunks";
import kuvadi from "../../assets/img/kuvadi.png";
import todos from "../../assets/img/todos.png";
import "./recovery.scss";

export type RecoveryParams = {
  token: string;
};

const Recovery = () => {
  const { token } = useParams<RecoveryParams>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  if (token) {
    <Navigate to="/notFound" />;
  }

  const RecoverySchema = object({
    password: string()
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .matches(/[a-z]/, "La contraseña debe tener al menos una letra minúscula")
      .matches(/[A-Z]/, "La contraseña debe tener al menos una letra mayúscula")
      .matches(/[0-9]/, "La contraseña debe tener al menos un número")
      .matches(
        /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/,
        "La contraseña debe tener al menos un caracter especial"
      )
      .required("La contraseña es obligatoria")
      .oneOf([ref("confirmPassword"), null], "Las contraseñas deben coincidir"),
    confirmPassword: string().required("Es necesario repetir la contraseña"),
    token: string().required(),
  });

  type RecoveryInput = InferType<typeof RecoverySchema>;
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<RecoveryInput>({
    resolver: yupResolver(RecoverySchema),
  });

  const onSubmit: SubmitHandler<RecoveryInput> = async (values) => {
    dispatch(resetPassword(values))
      .then((result) => {
        toast.success("Su contraseña a cambiado.")
        navigate("/");
      })
      .catch((err) => {
        toast.error(
          `Error al cambiar al realizar el cambio de contraseña: ${err}`
        );
      })
      .finally(() => {
        reset();
      });
  };

  return (
    <div className="hero is-fullheight is-fluid has-text-centered kvd-bg">
      <img
        className="login-logo m-4"
        src={kuvadi}
        alt="conozca_logo"
        width={300}
      />
      <nav className="level">
        <div className="level-item">
          <img src={todos} alt="Todos conozca" width={512} />
        </div>
        <div className="level-item">
          <div className="box login-form">
            <form
              className="m-5"
              noValidate
              onSubmit={handleSubmit(onSubmit)}>
              <h1 className="is-size-6 mb-4 has-text-link">Cambio de Contraseña</h1>
              <div className="field">
                <label className="label is-size-4 has-text-weight-light">Contraseña</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="userInput"
                    placeholder="Contraseña"
                    type="password"
                    className={`input is-rounded ${errors.password?.type === "required" && "is-danger"
                      }`}
                    {...register("password", { required: true })}
                    autoComplete="off"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                  <p className="help is-danger">
                    {errors.password?.message}
                  </p>
                </div>

              </div>
              <div className="field">
                <label className="label is-size-4 has-text-weight-light">Repetir Contraseña</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="userInput"
                    placeholder="Usuario"
                    type="password"
                    className={`input is-rounded ${errors.confirmPassword?.type === "required" && "is-danger"
                      }`}
                    {...register("confirmPassword", { required: true })}
                    autoComplete="off"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                  <p className="help is-danger">
                    {errors.confirmPassword?.message}
                  </p>
                </div>
              </div>
              <div style={{ display: "none" }}>
                <input
                  type="text"
                  id="token"
                  required
                  value={token}
                  {...register("token")}
                />
              </div>
              <button type="submit" className="button is-info">
                Guardar cambios
              </button>
            </form>
          </div>
        </div>
      </nav>
      <Footer />
    </div>
  );
};

export default Recovery;
