/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { AuthState, AuthTokens } from "../../interfaces/definition";

const initialState: AuthState = {};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials(state, action: PayloadAction<AuthTokens>) {
      const { accessToken, user, id, signature } = action.payload
      state.accessToken = accessToken;
      state.user = user;
      state.id = id;
      state.signature = signature;
    },
    logout() {
      return initialState;
    },
  }
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state: RootState) => state.auth.user;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;

