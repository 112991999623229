/* eslint-disable no-useless-catch */
import { AxiosResponse } from "axios";
import {
  QueryFilter,
  PaginationSchema,
  IReponse,
} from "interfaces/definition";
import { InferType } from "yup";
import { CampaignSchema, ScheduledSchema } from "schemas/formSchema";
import {api} from "./httpCommon";


type programing = InferType<typeof ScheduledSchema>;
type Campaign = InferType<typeof CampaignSchema>;

export async function fetchAllCampaings(
  params: QueryFilter
): Promise<PaginationSchema> {
  try {
    let url = "campaign?";
    if (params.page) {
      url += `page=${params.page}&`;
    }
    if (params.limit) {
      url += `limit=${params.limit}&`;
    }
    if (params.target) {
      url += `target=${params.target}&`;
    }
    if (params.query) {
      url += `query=${params.query}&`;
    }
    const response = await api.get(url.slice(0, -1));
    const { content } = response.data as IReponse;
    return content;
  } catch (error) {
    throw error;
  }
}

export async function fetchCampaing(campaignId: string): Promise<IReponse> {
  try {
    const response = await api.get(`campaign/${campaignId}`);
    return response.data as IReponse;
  } catch (error) {
    throw error;
  }
}

export async function saveCampaign(campaign: Campaign): Promise<AxiosResponse<any>> {
  try {
    const response = await api.post("campaign", campaign);
    return response
  } catch (error) {
    throw error;
  }
}

export async function UpdateCampaign(campaign: Campaign): Promise<IReponse> {
  try {
    const response = await api.put("campaign", campaign);
    return response.data as IReponse;
  } catch (error) {
    throw error;
  }
}

export async function fecthAllSends(campaignId: string): Promise<IReponse> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await api.get(`sends/${campaignId}`);
    const { content } = response.data as IReponse;
    return content;
  } catch (error) {
    throw error;
  }
}

export async function saveSend(send: FormData): Promise<AxiosResponse<any>> {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const response = await api.post("sends", send, config);
    return response;
  } catch (error) {
    throw error;
  }
} 

export async function savePrograming(program: programing) {
  try { 
    const response = await api.post(`schedule`, program);
    return response.data as IReponse;
  } catch (error) {
    throw error;  
  }
}


