import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useRemoveNewsletterMutation } from "api/apiSlice";
import { GET_NEWSLETTER_ALL } from "api/queries";
import { useModal, Modal, ConfirmationMondal } from "../../components";
import "./newsletter.scss";

const NewsHome = () => {
  const { isShown, toggle } = useModal();
  const [page, setPage] = useState(1);
  const [idTemplate, setIdTemplate] = useState<string>();
  const [removeMessage, setRemoveMessage] = useState("");
  const navigate = useNavigate();
  const [RemoveNewsletter, { isLoading }] = useRemoveNewsletterMutation();
  const {
    refetch,
    loading,
    error: errorNewsletter,
    data: listNewsletter = [],
  } = useQuery(GET_NEWSLETTER_ALL, {
    fetchPolicy: "no-cache",
    variables: {
      pagination: {
        page,
        limit: 20,
      },
    },
  });

  if (loading) {
    return <div>Cargando</div>;
  }

  if (errorNewsletter || !listNewsletter) {
    return <div>Something went wrong </div>;
  }

  const onSubmit = () => {
    navigate({
      pathname: "/admin/newsletter/build",
    });
  };

  const onRemove = async () => {
    toggle();
    try {
      const response = await RemoveNewsletter(idTemplate!).unwrap();
      const { message } = response;
      toast.success(message);
    } catch (error) {
      toast.error(`Un error ha ocurrido: ${error}`);
    }
    refetch();
  };

  const onEdit = (newsID) => {
    navigate("/admin/newsletter/build", {
      state: {
        newsletterID: newsID,
        isClone: false,
      },
    });
  };

  const onClone = (newsId) => {
    navigate("/admin/newsletter/build", {
      state: {
        newsletterID: newsId,
        isClone: true,
      },
    });
  };

  const generateImageGallery = (imagePaths): JSX.Element => {
    const imageItems = imagePaths.map((path, index) => (
      <div className="column is-3" key={`img${index + 1}`}>
        <figure className="image image-item" style={{ maxHeight: '350px', overflow: 'hidden' }}>
          <img src={path.img} alt=""/>
          <div className="overlay">
            <div className="content has-text-centered p-3">
              <h3 className="is-size-4 is-capitalized is-underlined">{`${path.name}`}</h3>
              <div className="buttons is-flex is-justify-content-center">
                {!path.approve && (
                  <button
                    type="button"
                    className="button is-primary is-outlined mr-2"
                    data-toggle="tooltip"
                    title="Editar"
                    onClick={() => onEdit(path.uuid)}
                  >
                    <i className="fa-solid fa-pencil" />
                  </button>
                )}
                <button
                  type="button"
                  className="button is-info is-outlined mr-2"
                  data-toggle="tooltip"
                  title="Clonar"
                  onClick={() => onClone(path.uuid)}
                >
                  <i className="fa-solid fa-clone" />
                </button>
                {!path.approve && (
                  <button
                    type="button"
                    className="button is-danger is-outlined"
                    data-toggle="tooltip"
                    title="Eliminar"
                    onClick={() => {
                      setRemoveMessage(`Desea eliminar la plantilla: ${path.name} ?`);
                      setIdTemplate(path.uuid);
                      toggle();
                    }}
                  >
                    <i className="fa-solid fa-trash-can" />
                  </button>
                )}
              </div>
            </div>
          </div>
        </figure>
      </div>
    ));
  
    return (
      <div className="columns is-multiline">
        {imageItems}
      </div>
    );
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Envios</li>
                <li>Plantillas</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Newsletters</h1>
              </div>
            </div>
            <div className="level-right">
              <div className="level-item">
                <button
                  type="button"
                  disabled={page === 1}
                  onClick={() => setPage((prev) => prev - 1)}
                  className="button">
                  <i className="fa-solid fa-chevron-left" />
                </button>
                <span className="is-capitalized mx-2">Pagina {page}</span>
                <button
                  type="button"
                  className="button"
                  disabled={listNewsletter.Newsletter.length === 0}
                  onClick={() => setPage((prev) => prev + 1)}>
                  <i className="fa-solid fa-chevron-right" />
                </button>
              </div>
              <div className="level-item">
                <button
                  type="button"
                  className="button mr-1"
                  onClick={onSubmit}>
                  <span className="icon">
                    <i className="fa-solid fa-pen-ruler" />
                  </span>
                  <span>Crear</span>
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={() => refetch()}>
                  <i className="fa-solid fa-arrows-rotate" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="card">
        <div className="card-content">
          {generateImageGallery(listNewsletter.Newsletter)}
        </div>
      </div>

      <Modal
        isShown={isShown}
        hide={toggle}
        headerText="Eliminar Newsletter"
        widthModal={30}
        modalContent={
          <ConfirmationMondal
            onConfirm={onRemove}
            onCancel={toggle}
            message={removeMessage}
          />
        }
      />
    </>
  );
};

export default NewsHome;
