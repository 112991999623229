/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import { Sends } from "../../interfaces/definition";

interface TableProps {
  send: Sends; // los datos de la tabla
}

// eslint-disable-next-line arrow-body-style
const SendTable = (props: TableProps) => {
  const {
    name,
    objetive,
    customer_focus,
    customer_category,
    age_category,
    genre,
    birthday_month,
    customer_rank,
    date_rank_start,
    date_rank_end,
    geo_hierarchy,
    geo_options,
    comm_hierarchy,
    comm_options,
    payment_method,
    payment_method_exception,
    condition_metric,
    cmetric_sign,
    cmetric_value,
    type_discount,
    next_campaing,
    type_discount_value,
    product_discount,
    type_reward,
    type_reward_value,
    product_reward,
    type_customer,
    type_customer_value,
    customer_file_options,
    campaign_name,
    previous_campaign_to,
    previous_campaign_send,
    filter_comm_hierarchy,
    filter_payment_method,
    filter_condition_metric,
    filter_type_discount,
    filter_type_reward,
  } = props.send;

  return (
    <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th />
          <th>Campo</th>
          <th>Valor</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td />
          <td>Nombre</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td />
          <td>Objetivo</td>
          <td>{objetive}</td>
        </tr>
        <tr>
          <td />
          <td>Foco</td>
          <td>{customer_focus}</td>
        </tr>
        <tr>
          <td />
          <td>Provabilida de respuesta</td>
          <td>{customer_category}</td>
        </tr>
        <tr>
          <td />
          <td>Rango de edad</td>
          <td>{age_category}</td>
        </tr>
        <tr>
          <td />
          <td>Genero</td>
          <td>{genre}</td>
        </tr>
        <tr>
          <td />
          <td>Mes de Cumpleaños</td>
          <td>{birthday_month}</td>
        </tr>
        <tr>
          <td />
          <td>Clientes que compraron entre</td>
          <td>{customer_rank}</td>
        </tr>
        <tr>
          <td />
          <td>fecha inicial</td>
          <td>{date_rank_start && new Date(date_rank_start).toDateString()}</td>
        </tr>
        <tr>
          <td />
          <td>fecha final</td>
          <td>{date_rank_end && new Date(date_rank_end).toDateString()}</td>
        </tr>
        <tr>
          <td />
          <td>Jerarquia Geografica</td>
          <td>{geo_hierarchy}</td>
        </tr>
        <tr>
          <td />
          <td>J.G. Opción</td>
          <td>{geo_options}</td>
        </tr>
        <tr>
          <td />
          <td>Jerarquia Comercial</td>
          <td>{comm_hierarchy}</td>
        </tr>
        <tr>
          <td />
          <td>J.C. Opción</td>
          <td>{comm_options}</td>
        </tr>
        <tr>
          <td />
          <td>Metodo de pago</td>
          <td>{payment_method}</td>
        </tr>
        <tr>
          <td />
          <td>Excepciones</td>
          <td>{JSON.stringify(payment_method_exception)}</td>
        </tr>
        <tr>
          <td />
          <td>Condicion Metrica</td>
          <td>{`${condition_metric} ${cmetric_sign} ${cmetric_value}`}</td>
        </tr>
        <tr>
          <td />
          <td>Tipo de descuento</td>
          <td>{type_discount}</td>
        </tr>
        <tr>
          <td />
          <td>Proxima campaña</td>
          <td>{next_campaing && new Date(next_campaing).toDateString()}</td>
        </tr>
        <tr>
          <td />
          <td>Otro descuento</td>
          <td>{type_discount_value}</td>
        </tr>
        <tr>
          <td />
          <td>Descuento producto</td>
          <td>{product_discount}</td>
        </tr>
        <tr>
          <td />
          <td>Tipo de Recompensa</td>
          <td>{type_reward}</td>
        </tr>
        <tr>
          <td />
          <td>Recompensa</td>
          <td>{type_reward_value}</td>
        </tr>
        <tr>
          <td />
          <td>Recompensa con producto</td>
          <td>{product_reward}</td>
        </tr>
        <tr>
          <td />
          <td>Tipo de cliente</td>
          <td>{type_customer}</td>
        </tr>
        <tr>
          <td />
          <td>Clientes</td>
          <td>{JSON.stringify(type_customer_value)}</td>
        </tr>
        <tr>
          <td />
          <td>Archivo cargado como</td>
          <td>{customer_file_options}</td>
        </tr>
        <tr>
          <td />
          <td>Campaña anterior</td>
          <td>{campaign_name}</td>
        </tr>
        <tr>
          <td />
          <td>Campaña anterior como</td>
          <td>{previous_campaign_to}</td>
        </tr>
        <tr>
          <td />
          <td>Envios anteriores</td>
          <td>{previous_campaign_send}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Jerarquia Comercial</td>
          <td>{filter_comm_hierarchy ? "SI" : "NO"}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Metodo de pago</td>
          <td>{filter_payment_method ? "SI" : "NO"}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Condición Metrica</td>
          <td>{filter_condition_metric ? "SI" : "NO"}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Metodo de Pago</td>
          <td>{filter_payment_method ? "SI" : "NO"}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Tipo de Descuento</td>
          <td>{filter_type_discount ? "SI" : "NO"}</td>
        </tr>
        <tr>
          <td />
          <td>Filtro Tipo de Recompensa</td>
          <td>{filter_type_reward ? "SI" : "NO"}</td>
        </tr>
      </tbody>
    </table>
  );
};
export default SendTable;
