import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAppDispatch } from "store";
import { UNSUBSCRIBE } from "features/sends/sendThunks";
import emailLogo from "../../assets/icons/mail.png";
import kuvadiLogo from "../../assets/img/kuvadi.png";
import "./unsubscribe.scss";



export type UnsubscribeParams = {
  email: string;
};

const UnSubscribeClient = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [statusMessages, setStatusMessages] = useState<string>("Te has dado de baja")
  const { email } = useParams<UnsubscribeParams>();

  const submit =(mail:string)=>{
    dispatch(UNSUBSCRIBE({email:mail}))
      .then((response) => {
        const{ status } = response.payload
       if(status !==200){
          setStatusMessages("No ha podido dar de baja en este momento")
       }

      })
      .catch((errorr) => {
        console.log(errorr)
      })
  }

  useEffect(() => {
    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(String(email).toLowerCase());
    };

    if (!email || !validateEmail(email)) {
      navigate("/NotFound");
    } else {
      submit(email);
    }
  }, [email, navigate, submit]);

  return (
      <div className="body-custom">
        <div className="logo-brand">
          <a href="https://kuvadi.co/" target="_blank" rel="noreferrer">
            <img src={kuvadiLogo} alt="LinkedIn Logo" />
          </a>
        </div>
        <div className="box">
          <img src={emailLogo} alt="Email Icon" />
          <h2 className="title is-4">{statusMessages}</h2>
          <p>Dejarás de recibir emails sobre nuevas publicaciones.</p>
          <hr />
        </div>
      </div>
  );
};

export default UnSubscribeClient;
