import { useQuery } from "@apollo/client";
import moment from "moment";
import { GET_PROGRAMMING_ACTIVE } from "./query";

const Home = () => {
  const { loading, error, data = [] } = useQuery(GET_PROGRAMMING_ACTIVE,{
    fetchPolicy:"cache-and-network",
    variables:{
      filter:{
        active:true
      }
    }
  });

  return (
    <div className="tile is-ancestor p-4">
          <div className="tile is-4 is-parent">
            <article className="tile is-child box">
              <p className="title is-5">Proximos Envios</p>
              {loading ? (
                <progress className="progress is-small is-primary" max="100">
                  15%
                </progress>
              ) : (
                <table className="table">
                  <thead>
                    <tr>
                      <th>Programación</th>
                      <th>Fecha</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.Programming && data.Programming.map((scheduling, index)=>(
                      <tr key={`${index + 1}`}>
                       <td>{scheduling.name}</td>
                       <td>{moment(scheduling.date_start).format("MMMM Do YYYY, h:mm:ss a")}</td>
                       </tr>
                    ))}
                  </tbody>
                </table>
              )}
              {error && (<p>{error.message}</p>)}
            </article>
          </div>
    </div>
  );
};

export default Home;
