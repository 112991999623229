import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { yupResolver } from "@hookform/resolvers/yup";
import { InferType, object, string } from "yup";
import { useAppDispatch } from "store";
import { Footer, showLoading } from "../../components";
import { forgotPassword } from "../../features/auth/authThunks";
import kuvadi from "../../assets/img/kuvadi.png";
import todos from "../../assets/img/todos.png";


const formSchema = object({
  email: string().required("Debe ingresar un correo valido"),
});

type forgotInput = InferType<typeof formSchema>;

const ForgotPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY as string;
  const captchaRef = useRef<ReCAPTCHA>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<forgotInput>({
    resolver: yupResolver(formSchema),
  });

  const onSubmit: SubmitHandler<forgotInput> = (data) => {
    const hideLoading = showLoading();
    dispatch(forgotPassword(data))
      .then((result) => {
        hideLoading();
        toast.success("Se ha enviado un correo con instrucciones.");
        navigate("/");
      })
      .catch((err) => {
        toast.error("Un error a ocurrido, intente nuevamente.");
        toast.error(`Error en la autenticación: ${err}`);
      })
      .finally(() => {
        reset();
      });
  };

  const returnHome = () => {
    navigate("/");
  };

  return (

    <div className="hero is-fullheight is-fluid has-text-centered kvd-bg">
      <img
        className="login-logo m-4"
        src={kuvadi}
        alt="conozca_logo"
        width={300}
      />
      <nav className="level">
        <div className="level-item">
          <img src={todos} alt="Todos conozca" width={512} />
        </div>
        <div className="level-item">
          <div className="box login-form">
            <form className="m-5" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="field">
                <label className="label is-size-4 has-text-weight-light">Usuario</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="userInput"
                    placeholder="Usuario"
                    type="text"
                    className={`input is-rounded ${errors.email?.type === "required" && "is-danger"
                      }`}
                    {...register("email", { required: true })}
                    autoComplete="off"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </div>
                {errors.email?.type === "required" && (
                  <p className="help is-danger">Datos no validos</p>
                )}
              </div>
              <button type="button" className="button is-text" onClick={()=> returnHome()}>
                Cancelar
              </button>
              <button type="submit" className="button is-link">
                Recuperar
              </button>
            </form>
          </div>
        </div>
      </nav>
      <ReCAPTCHA sitekey={captchaKey} ref={captchaRef} size="invisible" />
      <Footer />
    </div>

  );
};

export default ForgotPage;
