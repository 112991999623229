/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from "axios";
import { logout, setCredentials } from "features/auth/authSlice";
import { store } from "store";

async function obtenerTokenDeRefresco() {
  const { accessToken } = store.getState().auth;

  // Crear una instancia temporal de Axios para la solicitud de refresco
  const refreshApi = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/V1`,  // Puedes ajustar la versión según sea necesario
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const response = await refreshApi.post('/refresh-token', {
    accessToken
  });
  if (response?.data) {
    store.dispatch(setCredentials({
      ...response.data.accessToken
    }));
  } else {
    logout();
  }
}

function configureInterceptors(apiInstance) {
  apiInstance.interceptors.request.use(async (config) => {
    const { accessToken } = store.getState().auth;
    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  });

  apiInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        try {
          // Solicitar un nuevo token de refresco
          await obtenerTokenDeRefresco();
          const { accessToken } = store.getState().auth;
          // Actualizar el encabezado de autorización con el nuevo token
          apiInstance.defaults.headers.Authorization = `Bearer ${accessToken}`;

          // Reintentar la solicitud original con el nuevo token
          const originalRequest = error.config;
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return apiInstance(originalRequest);
        } catch (refreshError) {
          // Manejar el error al obtener el token de refresco
          return Promise.reject(refreshError);
        }
      }
      return Promise.reject(error);
    }
  );
}

function createApiInstance(version = 'V1'): AxiosInstance {
  const apiInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/${version}/`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  // Configurar interceptores en la nueva instancia
  configureInterceptors(apiInstance);

  return apiInstance;
}

const api = createApiInstance();

export { createApiInstance, api };
