import { useState } from "react";

const ImagenPreview = ({ imagenUrl, description }) => {
  const [mostrarModal, setMostrarModal] = useState(false);
  return (
    <div
      className="contenedor-imagen "
      onMouseEnter={() => setMostrarModal(true)}
      onMouseLeave={() => setMostrarModal(false)}>
      <img
        src={imagenUrl}
        alt={description}
        className="imagen-con-preview"
        title={description}
      />
 
        <div className={`modal2 modal ${mostrarModal ? `is-active` : ``}`}>
             <div className="modal-background"/>
            <div className="modal-content">
            <img src={imagenUrl} alt={description} />
            </div>
        
        </div>
 
    </div>
  );
};

export default ImagenPreview;
