import React, { useState } from 'react';
import "./rating.scss";

type QuestionProps = {
  question: string;
  onRatingChange: (rating: number) => void;
};

const Rating: React.FC<QuestionProps> = ({ question, onRatingChange }) => {
  const [rating, setRating] = useState(0);

  const handleRating = (rate: number) => {
    setRating(rate);
    onRatingChange(rate);
  };

  return (
    <div className='box is-shadowless'>
      <p className="subtitle is-5">{question}</p>
      <div className="stars">
        {[1, 2, 3, 4, 5].map((star) => (
          <i
            key={star}
            className={`fa-star ${rating >= star ? 'fas' : 'far'}`}
            onClick={() => handleRating(star)}
          />
        ))}
      </div>
    </div>
  );
};


export default Rating;
