import React from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AppsRegisterClient } from 'schemas/formSchema';
import { InferType } from 'yup';

interface Step3Props {
  formData: SendInput;
  setFormData: (data: any) => void;
  prevStep: () => void;
  submitForm: () => void;
}

type SendInput = InferType<typeof AppsRegisterClient>;

const Step3: React.FC<Step3Props> = ({ formData, setFormData, prevStep, submitForm }) => {
  const { register, handleSubmit, getValues } = useForm<SendInput>({
    defaultValues: formData
  });

  const onSubmit: SubmitHandler<SendInput> = data => {
    setFormData({ ...formData, ...data });
    submitForm();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='columns'>
        <div className="column is-half is-offset-one-quarter">
          <div className="card py-3">
            <div className="card-image">
              <figure className="image">
                 <img src={formData.photo} alt="Captured" />
              </figure>
            </div>
            <div className="card-content">
              <div className="media">
                <div className="media-left">  
                  <figure className="image is-48x48 " >
                  <span className="icon is-large">
                    <span className="fa-stack fa-lg">
                      <i className="fas fa-regular fa-circle-user fa-stack-2x"/>
                    </span>
                  </span>
                  </figure>
                </div>
                <div className="media-content">
                  <p className="title is-4">{`${getValues("name")} ${getValues("lastName")}`}</p>
                  <p className="subtitle is-6">{`${getValues("email")}`}</p>
                </div>
              </div>
              <div className="content">
                <h3 className='subtitle'>Confirma tus datos</h3>
                <br />
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <p className="subtitle is-6">Tipo Documento:</p>
                    </div>
                  </div>
                  <div className="level-right">
                    <p className="level-item"><strong>{`${getValues("document")}`}</strong></p>
                  </div>
                </div>
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <p className="subtitle is-6">Numero:</p>
                    </div>
                  </div>
                  <div className="level-right">
                    <p className="level-item"><strong>{`${getValues("number")}`}</strong></p>
                  </div>
                </div>
                <div className="level">
                  <div className="level-left">
                    <div className="level-item">
                      <p className="subtitle is-6">Movil:</p>
                    </div>
                  </div>
                  <div className="level-right">
                    <p className="level-item"><strong>{`${getValues("movil")}`}</strong></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="buttons is-flex is-justify-content-space-between px-5 mb-5">
        <button type="button" className="button is-info is-light is-medium" onClick={prevStep}>
          <span className="icon is-medium">
            <i className="fa-solid fa-circle-arrow-left fa-1x" />
          </span>
          <span>Anterior</span>
        </button>
        <button type="submit" className="button is-info is-medium">
          <span className="icon is-medium">
            <i className="fa-solid fa-arrow-right fa-1x" />
          </span>
          <span>Registrar</span>
        </button>
      </div>
    </form>
  );
};

export default Step3;
