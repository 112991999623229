import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { toast } from 'react-toastify';
import { InferType } from 'yup';
import { useAppDispatch } from "store";
import { Rating } from 'components';
import { GET_SURVEY_DATA, SAVESURVEY } from 'features/apps/appsThunks';
import { useEffect, useState } from 'react';
import { IReponse } from 'interfaces/definition';
import { SurveyInputs } from 'schemas/formSchema';
import { ReactComponent as GraciasSVG } from '../../assets/img/gracias.svg';
import "./survey.scss";


export type SurveyParams = {
  key: string;
  traceid: string;
};

type SurveyInput = InferType<typeof SurveyInputs>;

const Survey = () => {

  const { key, traceid } = useParams<SurveyParams>();
  const [userInfo, setUserInfo] = useState({ client: "", store: "" });
  const [showThanks, setShowThanks] = useState<boolean>(false)

  const formValuesDefault = {
    ratings: new Array(4).fill(0),
    apikey: key || "",
    observations: "",
    traceid: traceid || "",
  }

  const [defaultValues, setDegaultValues] = useState<SurveyInput>(formValuesDefault);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (key !== "" && traceid !== "") {
      dispatch(GET_SURVEY_DATA({ client: key as string, trace: traceid as string }))
        .then((response) => {
          const { data } = response.payload as AxiosResponse;
          setUserInfo(data || {});
        })
        .catch((errorr) => {
          toast.error(`un error ha ocurrido ${errorr}`);
        })
    }
  }, [key])

  const { handleSubmit, control, register, reset, formState: { errors } } = useForm<SurveyInput>({
    resolver: yupResolver(SurveyInputs),
    defaultValues,
  });

  const questions = [
    `¿Cómo califica su experiencia en ${userInfo.store}?`,
    '¿Cómo califica la atención de quién lo atendió?',
    '¿Cómo calificaría el producto o servicio?',
    '¿Cómo calificaría las instalaciones?',
  ];

  const onSubmit = (data: any) => {
    dispatch(SAVESURVEY(data))
      .then((response) => {
        const { message } = response.payload as IReponse;
        toast.success(message);
        reset();
        setDegaultValues(formValuesDefault);
        setShowThanks(true)
      })
      .catch((errorr) => {
        toast.error(`un error ha ocurrido ${errorr}`);
      })
  };

  return (
    <div className="container has-text-centered">
      {showThanks ? (
        <GraciasSVG className="svg-container" />

      ) : (
        <>
          <h1 className="title">Encuesta de Satisfacción</h1>
          <p className="subtitle mt-3 px-4">
            Hola <strong className='is-uppercase'>{userInfo?.client}</strong>, queremos saber cómo fue la experiencia en
            tu última compra en nuestra tienda <strong>{userInfo?.store}</strong>
          </p>
          <div className='box'>
            <form onSubmit={handleSubmit(onSubmit)}>
              {questions.map((question, index) => (
                <div key={`survey-${index + 1}`}>
                  <Controller
                    name={`ratings.${index}`}
                    control={control}
                    render={({ field }) => (
                      <Rating
                        question={question}
                        onRatingChange={(rating) => field.onChange(rating)}
                      />
                    )}
                  />
                  {errors.ratings &&
                    (<p className="help is-danger">
                      {errors.ratings[index]?.message as string}
                    </p>)}
                </div>
              ))}
              <div className="field is-horizontal">
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        className="input"
                        type="text"
                        placeholder="Observaciones"
                        {...register('observations')}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-comment-dots" />
                      </span>
                    </p>
                    {errors.observations && typeof errors.observations.message === 'string' && (
                      <p className="error">{errors.observations.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <button className="button is-primary" type="submit">Enviar</button>
            </form>
          </div>
        </>)}
    </div>
  );
};

export default Survey;
