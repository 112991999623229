import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { InferType } from 'yup';
import { AppsRegisterClient } from 'schemas/formSchema';
import { useAppDispatch } from "store";
import { ADDNEWCLIENT } from 'features/apps/appsThunks';
import { IReponse } from 'interfaces/definition';
import Step1 from './wizard/Step1';
import Step2 from './wizard/Step2';
import Step3 from './wizard/Step3';


type SendInput = InferType<typeof AppsRegisterClient>;

export type AppRegisterParams = {
  key: string;
};

const Register = () => {
  const { key } = useParams<AppRegisterParams>();
  const defaultValues:SendInput ={
    agree:false,
    photo:"",
    document:"",
    number:"",
    name:"",
    lastName:"",
    email:"",
    address:"",
    coutryCode:"+57",
    birthday:null,
    children:null,
    comments:null,
    gender:null,
    maritalStatus:null,
    movil:"",
    pets:null,
    phoneNumber:null,
    apikey:key || ""
  }
  const [step, setStep] = useState(1);
  const dispatch = useAppDispatch();

  const [formData, setFormData] = useState<SendInput>(defaultValues);
 

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const handleRefresh = () => {
    window.location.reload();
  };

  const submitForm = () => {    
    dispatch(ADDNEWCLIENT(formData))
      .then((response) => {
        const { message } = response.payload as IReponse;
        toast.success(message);
        setStep(1);
        setFormData(defaultValues);
        setTimeout(handleRefresh, 5000);
      })
      .catch((errorr) => {
        console.log(errorr)
        toast.error(`un error ha ocurrido ${errorr}`);
      })
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1 formData={formData} setFormData={setFormData} nextStep={nextStep} />;
      case 2:
        return <Step2 formData={formData} setFormData={setFormData} nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <Step3 formData={formData} setFormData={setFormData} prevStep={prevStep} submitForm={submitForm} />;
      default:
        return null;
    }
  };

  return (
    <>
    {renderStep()}
    </>
  );
};

export default Register;
