interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

const ConfirmationModal = ({
  onConfirm,
  onCancel,
  message,
}: ConfirmationModalProps) => (
  <>
  <div>{message}</div>
  <div className="field is-grouped is-grouped-right">
    <div className="control">
      <button
        type="button"
        className="button is-light is-small"
        onClick={onCancel}
      >
        No
      </button>
    </div>
    <div className="control">
      <button
        type="button"
        className="button is-primary is-small ml-3"
        onClick={onConfirm}
      >
        Si
      </button>
    </div>
  </div>
</>

);

export default ConfirmationModal;
