import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppDispatch } from "store";
import { yupResolver } from "@hookform/resolvers/yup";
import { IReponse } from "interfaces/definition";
import { InferType } from "yup";
import { UserSchema } from "schemas/formSchema";
import { createUser } from "features/auth/authThunks";


type userInputs = InferType<typeof UserSchema>;

const AddUser = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<userInputs>({
    resolver: yupResolver(UserSchema),
  });

  const onSumit: SubmitHandler<userInputs> = (data) => {
    dispatch(createUser(data))
      .then(() => navigate("/"))
      .catch((error) => {
        const { message } = error.data as IReponse;
        toast.error(message);
      });
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Configuración</li>
                <li>Usuarios</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item" />
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Nuevo usuario</h1>
              </div>
            </div>
            <div className="level-right" style={{ display: "none" }}>
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon"><i className="fa-solid fa-id-card"/></span>
             Datos del usuario
            </p>
          </header>
          <div className="card-content">
            <form onSubmit={handleSubmit(onSumit)}>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Nombres y Apellidos</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          type="text"
                          autoComplete="name"
                          className={`input ${
                            errors.first_name ? "is-danger" : ""
                          }`}
                          placeholder="Nombres"
                          aria-label="nombres"
                          {...register("first_name", {
                            required: true,
                          })}
                        />
                      </p>
                      <p className="help is-danger"> {errors.first_name?.message}</p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded ">
                      <input
                          type="text"
                          autoComplete="family-name"
                          className={`input ${
                            errors.last_name ? "is-danger" : ""
                          }`}
                          placeholder="Apellidos"
                          aria-label="apellidos"
                          {...register("last_name", { required: true })}
                        />
                      </p>
                      <p className="help is-danger">{errors.last_name?.message}</p>
                    </div>
                  </div>
              </div>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Email</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          type="email"
                          autoComplete="email"
                          className={`input ${
                            errors.email ? "is-danger" : ""
                          }`}
                          placeholder="email"
                          aria-label="email"
                          {...register("email", {
                            required: true,
                          })}
                        />
                      </p>
                      <p className="help is-danger">   {errors.email?.message}</p>
                    </div>
                  </div>
              </div>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Información de contacto</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          type="text"
                          autoComplete="street-address"
                          className={`input ${
                            errors.address ? "is-danger" : ""
                          }`}
                          placeholder="dirección"
                          aria-label="direccion"
                          {...register("address", {
                            required: true,
                          })}
                        />
                      </p>
                      <p className="help is-danger"> {errors.address?.message}</p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded ">
                        <input
                          type="text"
                          autoComplete="tel"
                          className={`input ${
                            errors.contact_phone ? "is-danger" : ""
                          }`}
                          placeholder="Telefono"
                          aria-label="telefono"
                          {...register("contact_phone", {
                            required: true,
                          })}
                        />
                      </p>
                      <p className="help is-danger"> {errors.contact_phone?.message}</p>
                    </div>
                  </div>
              </div>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Contraseña</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                            type="password"
                            autoComplete="new-password"
                            className={`input ${
                              errors.password ? "is-danger" : ""
                            }`}
                            placeholder="contraseña"
                            aria-label="contraseña"
                            {...register("password")}
                          />
                      </p>
                      <p className="help is-danger">  {errors.password?.message}</p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded ">
                      <input
                        autoComplete="new-password"
                        type="password"
                        className={`input ${
                          errors.confirm_password ? "is-danger" : ""
                        }`}
                        placeholder="confirmar contraseña"
                        aria-label="confirmar-contraseña"
                        {...register("repeat_password")}
                      />
                      </p>
                      <p className="help is-danger"> {errors.confirm_password?.message}</p>
                    </div>
                  </div>
              </div>
              <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Rol</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          type="text"
                          className="input"
                          placeholder="Perfil"
                          aria-label="perfil"
                          {...register("role", {
                            required: true,
                          })}
                        />
                      </p>
                      <p className="help is-danger"> {errors.role?.message}</p>
                    </div>
                    <div className="field">
                      <p className="control pt-2">
                        <label className="checkbox">
                        <input
                        className="mr-1"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                            {...register("active", {
                              required: true,
                            })}
                          />
                          Activo
                        </label>
                        </p>
                      <p className="help is-danger">{errors.last_name?.message}</p>
                    </div>
                  </div>
              </div>
              <hr/>
              <div className="field is-horizontal">
                <div className="field-label"/>
                <div className="field-body">
                  <div className="field">
                    <div className="field is-grouped">
                      <div className="control">
                        <button type="submit" className="button is-primary">
                          <span> Crear</span>
                        </button>
                      </div>
                      <div className="control">
                        <button type="button" className="button is-primary is-outlined" onClick={() => {
                          reset();
                        }}>
                          <span>Limpiar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddUser;
