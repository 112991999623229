/* eslint-disable no-useless-catch */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { AxiosError } from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ResetPassword,
} from "interfaces/definition";
import { InferType } from "yup";
import { UserSchema } from "schemas/formSchema";
import {api} from "api/httpCommon";
import { logout } from "./authSlice";
import { forgot, changuePassword, logOut } from "../../api/authApi";

type user = InferType<typeof UserSchema>;

export const logoutUser = createAsyncThunk(
  "auth/logoutUser",
  async (_, { dispatch ,rejectWithValue}) => {
    try {
      const response  = await logOut();
      console.log(response)
      dispatch(logout());
      return response;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw error; // Si no hay respuesta del servidor, arrojamos el error original
      }
      const { data, status } = error.response;
      return rejectWithValue({ data, status });
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/forgotPassword",
  async (params: { email: string }, { rejectWithValue }) => {
    try {
      const response = await forgot(params);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw error; // Si no hay respuesta del servidor, arrojamos el error original
      }
      const { data, status } = error.response;
      return rejectWithValue({ data, status });
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (params: ResetPassword, { rejectWithValue }) => {
    try {
      const response = await changuePassword(params);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw error; // Si no hay respuesta del servidor, arrojamos el error original
      }
      const { data, status } = error.response;
      return rejectWithValue({ data, status });
    }
  }
);

export const createUser = createAsyncThunk(
  "auth/createUser",
  async(userData:user, { rejectWithValue})=>{
    try{
      const response = await api.post("user",userData)
      return response;
    }catch(error){
      return rejectWithValue(error)
    }
  }
)

export const  signUp = createAsyncThunk(
  "auth/signup",
  async(userData:user, { rejectWithValue})=>{
    try{
      const response = await api.post("signup",userData)
      return response;
    }catch(error){
      return rejectWithValue(error)
    }
  }
)
