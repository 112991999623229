import { useState } from "react";
import whatsapp from "../../assets/icons/whatsapp.png";
import mailIcon from "../../assets/icons/email.png";
import linkedin from "../../assets/icons/linkedin.png";

const Footer = () => {
  const [year] = useState<Date>(new Date());

  return (
    <footer className="footer has-background-info-dark p-3">
      <div className="content has-text-centered has-text-white">
        <div>
          <span className="mx-3">
            <a
              href="mailto:hello@conozca.co" target="_blank" rel="noreferrer"
              className="has-text-white-bis is-inline-flex is-align-items-center">
              <img src={mailIcon} alt="email" width={32} className="mr-3" />
              hello@conozca.co
            </a>
          </span>
          <span className="mx-3">
            <a
              href="https://api.whatsapp.com/send?phone=573005431020&text=%F0%9F%91%8B%20Hola%2C%20quiero%20saber%20mas%20de%20Kuvadi%20y%20como%20podemos%20trabajar%20juntos"
              className="has-text-white-bis is-inline-flex is-align-items-center">
              <img src={whatsapp} alt="whatsapp" width={32} className="mr-3" />
              +57 3005431020
            </a>
          </span>
          <span className="mx-3">
            <a
              href="https://www.linkedin.com/company/conozca/"
              target="_blank"
              rel="noreferrer" className="has-text-white-bis is-inline-flex is-align-items-center">
              <img src={linkedin} alt="linkedin" width={32} className="mr-3" />
              Conozca
            </a>
          </span>
        </div>
        <div className="my-3">
          <span className="mx-3">
            <a href="https://kuvadi.co/privacy/" className="has-text-white-bis" target="_blank" rel="noreferrer">
            Política de privacidad
            </a>
          </span>
          <span className="mx-3">
            <a href="https://kuvadi.co/cookies/" className="has-text-white-bis" target="_blank" rel="noreferrer">
            Política de cookies
            </a>
          </span>
          <span className="mx-3">
            <a href="https://kuvadi.co/terminos/" className="has-text-white-bis" target="_blank" rel="noreferrer">
            Términos de servicio
            </a>
          </span>
        </div>
        <span>Conozca &copy; {year.getFullYear()}</span>
      </div>
    </footer>
  );
};

export default Footer;
