import { InferType } from "yup";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useQuery } from "@apollo/client";
import moment from "moment";
import { useAppDispatch } from "store";
import { SetcampaignId, SetSendAfter } from "features/campaing/campaignSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddSendSchema } from "schemas/formSchema";
import { GET_CAMPAIGNS } from "../../api/queries";

type AddSendInput = InferType<typeof AddSendSchema>;

const AddSends = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { loading, error, data } = useQuery(GET_CAMPAIGNS, {
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-first",
  });

  const onSubmit: SubmitHandler<AddSendInput> = (formData) => {
    dispatch(SetcampaignId(formData.campaingIdentifier));
    dispatch(SetSendAfter(formData.numberSends));
    navigate(
      `/admin/campaign/${formData.campaingIdentifier}/sends/${formData.numberSends}`
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<AddSendInput>({
    resolver: yupResolver(AddSendSchema),
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error : {error.message}</p>;
  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Campañas</li>
                <li>Perfiles</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Agregar Perfilamiento</h1>
              </div>
            </div>
            <div className="level-right" style={{ display: "none" }}>
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="fa-brands fa-wpforms" />
              </span>
              Seleccione la campaña e ingrese el numero de perfiles
            </p>
          </header>
          <div className="card-content">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Campaña</label>
                </div>
                <div className="field-body">
                  <div className="field is-narrow">
                    <div className="control">
                      <div className="select is-fullwidth">
                        <select
                          className={`${
                            errors.campaingIdentifier && "is-danger"
                          }`}
                          id="campaing"
                          placeholder="campaña"
                          {...register("campaingIdentifier")}>
                          <option
                            hidden
                            defaultValue=""
                            aria-label="seleccionar"
                          />
                          {data.Campaigns &&
                            data.Campaigns.map((campaign, index) => (
                              <option
                                key={`campaign-${index + 1}`}
                                value={campaign.identifier}>
                                {`${campaign.name}     ${moment(
                                  campaign.dateEnd
                                ).format("MMM Do YY")}`}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {errors.campaingIdentifier?.type === "required" && (
                      <p className="help is-danger">
                        {errors.campaingIdentifier?.message}
                      </p>
                    )}
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <input
                        type="number"
                        className={`input ${
                          errors.numberSends?.type === "required" && "is-danger"
                        }`}
                        id="numberSends"
                        min={1}
                        placeholder="perfiles"
                        {...register("numberSends")}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-hashtag" />
                      </span>
                    </p>
                    {errors.numberSends?.type === "required" && (
                      <p className="help is-danger">
                        {errors.numberSends?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="field is-horizontal">
                <div className="field-label" />
                <div className="field-body">
                  <div className="field">
                    <div className="field is-grouped">
                      <div className="control">
                        <button type="submit" className="button is-primary">
                          <span> Agregar</span>
                        </button>
                      </div>
                      <div className="control">
                        <button
                          type="button"
                          className="button is-primary is-outlined"
                          onClick={() => {
                            reset();
                          }}>
                          <span>Limpiar </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
export default AddSends;
