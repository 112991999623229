import ReactDOM from "react-dom";

interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element;
  headerText: string;
  widthModal: number;
}

const Modal = ({
  isShown,
  hide,
  modalContent,
  headerText,
}: ModalProps) => {
  const modal = (
    <div className={`modal ${isShown ? 'is-active' : ''}`}>
      <div className="modal-background" onClick={hide}/>
      <div className="modal-card" >
        <header className="modal-card-head">
          <p className="modal-card-title">{headerText}</p>
          <button type="button" className="delete" aria-label="close" onClick={hide}/>
        </header>
        <section className="modal-card-body">
          {modalContent}
        </section>
      </div>
      <button type="button" className="modal-close is-large" aria-label="close" onClick={hide}/>
    </div>
  );

  return ReactDOM.createPortal(
    modal,
    document.getElementById("modal-root") as HTMLElement
  );
};

export default Modal;
