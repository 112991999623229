import { createAsyncThunk } from "@reduxjs/toolkit";
import { QueryFilter } from "interfaces/definition";
import {
  UpdateCampaign,
  fecthAllSends,
  fetchAllCampaings,
  fetchCampaing,
  saveCampaign,
  saveSend,
} from "api/campaingApi";
import axios, { AxiosError, AxiosResponse } from "axios";
import { InferType } from "yup";
import { CampaignSchema } from "schemas/formSchema";
import {api} from "api/httpCommon";

type Campaign = InferType<typeof CampaignSchema>;

export const GetCampaingById = createAsyncThunk(
  "campaigns/getCampaigns",
  async (campaignId: string, { rejectWithValue }) => {
    try {
      const response = await fetchCampaing(campaignId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw error; // Si no hay respuesta del servidor, arrojamos el error original
      }
      const { data, status } = error.response;
      return rejectWithValue({ data, status });
    }
  }
);

export const addCampaings = createAsyncThunk(
  "campaigns/addCampaign",
  async (campaign: Campaign, { rejectWithValue }) => {
    try {
      const response:AxiosResponse<any> = await saveCampaign(campaign);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error);
    }
  }
);

export const removeCampaign =createAsyncThunk(
  "campaigns/remove",
  async (campaignId:string,{rejectWithValue})=>{
    try{
      const response =await api.delete(`campaign/${campaignId}`)
      return response;
    }catch(error){
      return rejectWithValue(error);
    }
  }
)

export const updatedCampaings = createAsyncThunk(
  "campaigns/addCampaign",
  async (campaign: Campaign, { rejectWithValue }) => {
    try {
      const response = await UpdateCampaign(campaign);
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const fetchSends = createAsyncThunk(
  "campaigns/getsends",
  async (campaignId: string, { rejectWithValue }) => {
    try {
      const response = await fecthAllSends(campaignId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      if (!error.response) {
        throw error; // Si no hay respuesta del servidor, arrojamos el error original
      }
      const { data, status } = error.response;
      return rejectWithValue({ data, status });
    }
  }
);

export const addSend = createAsyncThunk(
  "campaigns/addSend",
  async (send: FormData, { rejectWithValue }) => {
    try {
      const response = await saveSend(send);
      return response;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error);
    }
  }
);

export const removeSend = createAsyncThunk(
  "sends/remove",
  async(sendId:string,{rejectWithValue})=>{
  try{
    const response = await api.delete(`sends/${sendId}`)
    return response;
  }catch(error){
    return rejectWithValue(error)
  }}
)

