import { useParams } from 'react-router-dom';
import { InferType } from 'yup';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { RegisterVisit } from 'schemas/formSchema';
import { useAppDispatch } from "store";
import { GET_STORE_LIST, REGISTERVISIT} from 'features/apps/appsThunks';
import { useEffect, useState } from 'react';
import { IReponse } from 'interfaces/definition';
import { AxiosResponse } from 'axios';


export type AppRegisterParams = {
    key: string;
};

type RegisterVisitInput = InferType<typeof RegisterVisit>;

const Purcharse = () => {

    const { key } = useParams<AppRegisterParams>();
    const dispatch = useAppDispatch();
    const [storeList, setStoreList] = useState([]);
    const { register, handleSubmit,reset, formState: { errors } } = useForm<RegisterVisitInput>({
        defaultValues: {
            apikey: key || ""
        },
        resolver: yupResolver(RegisterVisit),
    });

    const onSubmit = (data) => {
        dispatch(REGISTERVISIT(data))
        .then((response) => {
          const { content } = response.payload as IReponse;
          toast.success(content);
          reset();
        })
        .catch((errorr) => {
          toast.error(`un error ha ocurrido ${errorr}`);
        })
    };

    useEffect(() => {
        if (key !== "") {
            dispatch(GET_STORE_LIST(key as string))
                .then((response) => { 
                    const { data } = response.payload as AxiosResponse
                    setStoreList(data || []);
                })
                .catch((errorr) => {
                    toast.error(`un error ha ocurrido ${errorr}`);
                })
        }
    }, [key, dispatch])

    return (
        <div className="columns">
            <div className="column right has-text-centered">
                <form onSubmit={handleSubmit(onSubmit)}>
                <nav className="level">
                    <div className="level-left" />
                    <div className="level-right">
                        <div className="level-item">
                            <div className="field is-horizontal">
                                <div className="field-body">
                                    <div className="field">
                                        <p className="control is-expanded has-icons-left">
                                            <div className={`select is-fullwidth ${errors.store && "is-danger"}`}>
                                                <select {...register("store")}>
                                                <option value="">Tienda</option>
                                                {storeList.map((option) => (
                                                    <option
                                                        key={option.cod_almacen.toLowerCase()}
                                                        value={option.cod_almacen.toLowerCase()}>
                                                        {option.nombre_almacen}
                                                    </option>
                                                    ))}
                                                </select>
                                            </div>
                                            <span className="icon is-small is-left">
                                                <i className="fa-solid fa-store" />
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                    <h1 className="title is-4">Registrar Visita</h1>
                    <p className="description is-5">Puede realizar la busqueda del cliente por numero de identificación, email o numero de celular.</p>
                    <div className="field is-horizontal">
                        <div className="field-body">
                            <div className="field">
                                <p className="control is-expanded has-icons-left">
                                    <input className={`input ${errors.user && "is-danger"}`} type="text" placeholder="Email, Numero de identificación o Celular" {...register("user")} />
                                    <span className="icon is-small is-left">
                                        <i className="fa-regular fa-address-book" />
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <button className="button is-info" type='submit'>
                        <span className="icon">
                            <i className="fa-regular fa-paper-plane" />
                        </span>
                        <span>Registrar</span>
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Purcharse;
