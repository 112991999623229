import { useLocation, useNavigate } from "react-router-dom";
import { InferType } from "yup";
import { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import { useAppDispatch } from "store";
import { CampaignSchema } from "schemas/formSchema";
import {
  addCampaings,
  updatedCampaings,
} from "features/campaing/campaingThunks";
import { EDIT_CAMPAIGN_ID } from "api/queries";
import { SetcampaignId, SetSendAfter } from "features/campaing/campaignSlice";
import { IReponse } from "../../interfaces/definition";

type CampaignInput = InferType<typeof CampaignSchema>;

const CampaignView = () => {
  const navigate = useNavigate();
  const dispactch = useAppDispatch();
  const { state } = useLocation();
  const [editCamapaign, { data }] = useLazyQuery(EDIT_CAMPAIGN_ID);
  const [defaultValue, setDefaultValue] = useState<CampaignInput>();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm<CampaignInput>({
    resolver: yupResolver(CampaignSchema),
  });

  useEffect(() => {
    if (state != null) {
      editCamapaign({
        variables: {
          filter: {
            id: state?.campaignId,
          },
        },
        fetchPolicy:"no-cache"
      });
    }
  }, [state, editCamapaign]);

  useEffect(() => {
    if (data && data.Campaigns && data.Campaigns.length > 0) {
      setDefaultValue(data.Campaigns[0]);
    }
  }, [data]);

  useEffect(() => {
    if (defaultValue != null) {
      const campaingValue = defaultValue as CampaignInput;
      setValue("identifier", campaingValue.identifier);
      setValue("name", campaingValue.name);
      setValue("description", campaingValue.description);
      setValue("date_start", new Date(campaingValue.date_start));
      setValue("date_end", new Date(campaingValue.date_end));
      setValue("numberSends", 1);
      setIsEdit(true);
    }
  }, [defaultValue]);

  const onSubmit: SubmitHandler<CampaignInput> = (formValues) => {
    if (isEdit) {
      dispactch(updatedCampaings(formValues))
      .then(() => {
        toast.success("Campaña actualizada");
      })
      .catch((err) => {
        toast(`Un error ha ocurrido, intente nuevamente ${err}`);
      })
      .finally(() => {
        reset();
      });
    } else {
      dispactch(addCampaings(formValues))
        .then((response) => {
            toast.success("Campaña creada. agregue los detalles de envio.");
            dispactch(SetcampaignId(response.payload));
            dispactch(SetSendAfter(formValues.numberSends));
            navigate(
              `/admin/campaign/${response.payload}/sends/${formValues.numberSends}`
            );
        })
        .catch((err) => {
          toast(`Un error ha ocurrido, intente nuevamente ${err}`);
        })
        .finally(() => {
          reset();
        });
    }
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Campañas</li>
                <li>Nueva Campaña</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h2 className="title">Registro</h2>
              </div>
            </div>
            <div className="level-right" style={{ display: "none;" }}>
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="fa-brands fa-wpforms" />
              </span>
              Información de la campaña
            </p>
          </header>
          <div className="card-content">
            <form
              autoComplete="off"
              className="mb-5"
              onSubmit={handleSubmit(onSubmit)}>

              { !isEdit && (<div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label"> No. envios</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <input
                        type="number"
                        min="0"
                        className={`input ${
                          errors.numberSends?.type === "required" && "is-danger"
                        }`}
                        {...register("numberSends")}
                        aria-describedby="sends-help"
                        autoComplete="off"
                        disabled={isEdit}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-hashtag" />
                      </span>
                    </p>
                    <p className="help is-link">
                      Numero de envios a realizar sin importar el medio.
                    </p>
                    {errors.numberSends?.type === "required" && (
                      <p className="help is-danger">
                        {errors.numberSends?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>)}
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Nombre</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        type="text"
                        className={`input ${
                          errors.name?.type === "required" && "is-danger"
                        }`}
                        id="campaing"
                        autoComplete="off"
                        {...register("name")}
                      />
                      <span className="icon is-small is-left">
                        <i className="mdi mdi-account" />
                      </span>
                    </p>
                    {errors.name?.type === "required" && (
                      <p className="help is-danger">{errors.name?.message}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label"> Programación</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <Controller
                        name="date_start"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            id="scheduleDateStart"
                            minDate={new Date()}
                            dateFormat="yyyy/MM/dd"
                            onChange={(date) => field.onChange(date)}
                            className={`input ${
                              errors.date_start?.type === "required" &&
                              "is-danger"
                            }`}
                            placeholderText="Fecha de inicio"
                            selected={field.value}
                            isClearable
                            withPortal
                          />
                        )}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    </p>
                    {errors.date_start?.type === "required" && (
                      <p className="help is-danger">
                        {errors.date_start?.message}
                      </p>
                    )}
                  </div>
                  <div className="field">
                    <p className="control is-expanded has-icons-left has-icons-right">
                      <Controller
                        name="date_end"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            minDate={new Date()}
                            dateFormat="yyyy/MM/dd"
                            onChange={(date) => field.onChange(date)}
                            className={`input ${
                              errors.date_end?.type === "required" &&
                              "is-danger"
                            }`}
                            placeholderText="Fecha de fin"
                            selected={field.value}
                            isClearable
                            withPortal
                          />
                        )}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    </p>
                    {errors.date_end?.type === "required" && (
                      <p className="help is-danger">
                        {errors.date_end?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Descripción</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <textarea
                        className={`textarea ${
                          errors.description?.type === "required" && "is-danger"
                        }`}
                        placeholder="Descripción de la campaña"
                        id="description"
                        rows={3}
                        {...register("description")}
                      />
                    </div>
                    {errors.description?.type === "required" && (
                      <p className="help is-danger">
                        {errors.description?.message}
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="field is-horizontal">
                <div className="field-label" />
                <div className="field-body">
                  <div className="field">
                    <div className="field is-grouped">
                      <div className="control">
                        <button type="submit" className="button is-primary">
                          <span>{isEdit ? "Actualizar" : "Enviar"}</span>
                        </button>
                      </div>
                      <div className="control">
                        <button
                          type="button"
                          className="button is-primary is-outlined"
                          onClick={() => {
                            reset();
                          }}>
                          <span>Limpiar</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default CampaignView;
