import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "store";
import { SUSCRIPTIONPREFERENCES } from "features/sends/sendThunks";
import emailLogo from "../../assets/icons/mail.png";
import kuvadiLogo from "../../assets/img/kuvadi.png";
import "./unsubscribe.scss";



function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Preferences = () => {
  const dispatch = useAppDispatch();
  const query = useQuery()
  const action = query.get('action');
  const email = query.get('email');
  const token = query.get('token');
  const [urlSuscribe, setUrlSuscribe]= useState<string>("#/")
  const [statusMessages, setStatusMessages] = useState<string>("Te has dado de baja")
 
  const submit=()=>{
    dispatch(SUSCRIPTIONPREFERENCES({email:email as string,action:action as string,token: token as string}))
      .then((response) => {
        const{ status } = response.payload
        if(status !==200){
           setStatusMessages("No ha podido dar de baja en este momento")
        }
      })
      .catch((errorr) => {
        console.log(errorr)
      })
  }
  useEffect(()=>{
    submit()
  },[action, email, token])

  return (
    <div className="body-custom">
        <div className="logo-brand">
          <img src={kuvadiLogo} alt="LinkedIn Logo" />
        </div>
        <div className="box">
          <img src={emailLogo} alt="Email Icon" />
          <h2 className="title is-4">{statusMessages}</h2>
          <p>Dejarás de recibir emails sobre nuevas publicaciones.</p>
          <hr />
          <div className="bottom-links">
            <p>¿Te has dado de baja por error? <a href={urlSuscribe}>Suscríbete de nuevo</a></p>
          </div>
        </div>
      </div>
  );
};

export default Preferences;
