import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { addSend } from "features/campaing/campaingThunks";
import {
  campaignId,
  incrementSaveCount,
  isComplete,
  resetCampaign,
} from "features/campaing/campaignSlice";
import "react-datepicker/dist/react-datepicker.css";
import { IReponse } from "interfaces/definition";
import {
  GET_SEND_PROFILE,
  GET_CLIENT_LOCATIONS,
  GET_CLIENT_REFERENCE,
  GET_SENDS_HEADER_CAMPAING,
} from "api/queries";
import { useModal, Modal, showLoading, ProfileForm } from "../../components";
import customer_category from "../../data/customer_category.json";
import age_category from "../../data/age_category.json";
import birthday from "../../data/birthday_month.json";


const Sends = (): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const campaignID = useSelector(campaignId);
  const completedSend = useSelector(isComplete);
  const { isShown, toggle } = useModal();

  const { loading, error, data } = useQuery(GET_SEND_PROFILE, {
    variables: {
      filter: {
        notCampaign: campaignID,
      },
    },
  });

  const client = useApolloClient();
  const [clientProducts, setClientProducts] = useState([]);
  const [clientProductsForDiscounts, setClientProductsForDiscounts] = useState(
    []
  );
  const [clientProductsForRewards, setClientProductsForRewards] = useState([]);
  const [clientLocations, setclientLocations] = useState([]);
  const [sends, setSends] = useState([]);

  const handleClose = () => {
    navigate("/admin");
  };

  useEffect(() => {
    if (completedSend) {
      dispatch(resetCampaign());
      navigate("/admin");
    }
  }, [completedSend]);


  if (loading) {
    return <div>Cargando</div>;
  }

  if (error) {
    return <div>Something went wrong </div>;
  }

  const onSubmit = (datos) => {
    const formData = new FormData();
    const hideLoading = showLoading();
    const data2Send = {
      campaign_identifier: campaignID,
      sends: datos,
    };
    formData.append("sends", JSON.stringify(data2Send));
    formData.append("file", datos.customer_file && datos.customer_file[0]);

    dispatch(addSend(formData))
      .then((response) => {
        dispatch(incrementSaveCount());
        toast.success(response.payload as string);
      })
      .catch((errorr) => {
        toast.error(`un error ha ocurrido ${errorr}`);
      })
      .finally(()=>{
        hideLoading()
      })

    hideLoading();
  };

  const getGeographicData = async (selectedOption: string) => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_CLIENT_LOCATIONS,
        variables: {
          query: selectedOption,
        },
      });
      setclientLocations(datosresponse.ClientLocations);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  const getComercialData = async (selectedOption: string) => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_CLIENT_REFERENCE,
        variables: {
          query: selectedOption,
        },
      });
      setClientProducts(datosresponse.ClientProducts);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  const getProductForDiscounts = async () => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_CLIENT_REFERENCE,
        variables: {
          query: "linea",
        },
      });
      setClientProductsForDiscounts(datosresponse.ClientProducts);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  const getRewars = async () => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_CLIENT_REFERENCE,
        variables: {
          query: "linea",
        },
      });
      setClientProductsForRewards(datosresponse.ClientProducts);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  const getSendBeforeCampaign = async (selectedOption: string) => {
    try {
      const { data: datosresponse } = await client.query({
        query: GET_SENDS_HEADER_CAMPAING,
        variables: {
          filter: { campaign: selectedOption },
        },
        fetchPolicy: "no-cache",
      });
      setSends(datosresponse.Sends);
    } catch (errort) {
      console.error("Error al obtener datos:", errort);
    }
  };

  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Campañas</li>
                <li>Nuevo envio</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Envio</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProfileForm
        submitForm={onSubmit}
        getGeographicalHierarchy={getGeographicData}
        getComercialHierarchy={getComercialData}
        getProductsForDiscounts={getProductForDiscounts}
        getRewards={getRewars}
        getSendBeforeCampaign={getSendBeforeCampaign}
        dataAgeCategory={age_category}
        dataBirthdayMonth={birthday}
        dataCampaings={data.Campaigns}
        dataComercialHierarchy={data.BusinessRanking}
        dataComercialHierarchyOption={clientProducts}
        dataConditionsMetrics={data.ConditionsMetrics}
        dataCustomerCategory={customer_category}
        dataCustomerFocusList={data.CustomerFocus}
        dataCustomerType={data.CustomerType}
        dataDiscountsRates={data.DiscountsRates}
        dataGeographicalHierarchy={data.GeographicalRanking}
        dataGeoHierarchyOption={clientLocations}
        dataProductsForDiscounts={clientProductsForDiscounts}
        dataObjetive={data.TargetCampaigns}
        dataPaymentMethods={data.PaymentMethods}
        dataProductsForRewards={clientProductsForRewards}
        dataRewardsType={data.RewardsType}
        dataSends={sends}
        loading={false}
      />
      <Modal
        isShown={isShown}
        hide={toggle}
        headerText="Formulario de Envios"
        widthModal={50}
        modalContent={
          <>
            <div>
              <p>Se han registrado todos los envios.</p>
            </div>
            <button
              type="button"
              className="btn btn-outline-success"
              onClick={handleClose}>
              Terminar
            </button>
          </>
        }
      />
    </>
  );
};

export default Sends;
