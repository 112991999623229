import { Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Login,
  ForgotPage,
  Home,
  CampaignView,
  AllCampaigns,
  Newsletter,
  Sends,
  Schedule,
  AddSends,
  AddUser,
  NewsHome,
  ProgrammingList,
  DefaultPage,
  UnSubscribeClient,
  ActivationAccount,
  NotFound,
  Recovery,
  Register,
  Purcharse,
  Survey,
  Preferences
} from "../views";

import RequiereAuth from "./layouts/RequireAuthLayout";
import Baselayout from "./layouts/BaseLayout";
import AppsLayout from "./layouts/AppsLayout";

import { selectAccessToken } from "../features/auth/authSlice";

const IndexRoutes = (): JSX.Element => {
  const [hasSubdomain, setHasSubdomain] = useState<boolean>(false);
  const token = useSelector(selectAccessToken);

  useEffect(() => {
    if (token) {
      document.documentElement.classList.remove('has-aside-left', 'has-aside-mobile-transition', 'has-navbar-fixed-top', 'has-aside-expanded');
    }
  }, []);

  useEffect(() => {
    // eslint-disable-next-line prefer-destructuring
    const hostname = window.location.hostname;

    const parts = hostname.split(".");
    if (parts.length > 2) {
      const subdomain = parts[0];
      if (subdomain !== "www") setHasSubdomain(true);
    }
  }, []);

  return (
    <>
      <Routes>
        {hasSubdomain ? (
          <>
            <Route path="/" element={<Baselayout />}>
              <Route index element={<Login />} />
              <Route path="/unsubscribe/:email" element={<UnSubscribeClient />} />
              <Route path="/forgot" element={<ForgotPage />} />
              <Route path="/account/recovery/:token" element={<Recovery />} />
              <Route path="/registro/:token" element={<ActivationAccount />} />
            </Route>
            <Route path="/admin" element={<RequiereAuth />}>
              <Route index element={<Home />} />
              <Route path="campaign" element={<CampaignView />} />
              <Route path="campaigns/add-send" element={<AddSends />} />
              <Route
                path="campaign/:campaignId/sends/:totalSends"
                element={<Sends />}
              />
              <Route path="campaign/all" element={<AllCampaigns />} />
              <Route path="newsletter" element={<NewsHome />} />
              <Route path="newsletter/build" element={<Newsletter />} />

              <Route path="sends/schedule" element={<Schedule />} />
              <Route path="sends/scheduled-list" element={<ProgrammingList />} />
              <Route path="new-user" element={<AddUser />} />
            </Route>
          </>
        ) : (
          <Route path="/">
            <Route index element={<DefaultPage />} />
            <Route path="/unsubscribe/:email" element={<UnSubscribeClient />} />
            <Route path="/preferences" element={<Preferences />} />
            <Route path="/apps/:key" element={<AppsLayout/>}>
              <Route path="registro/" element={<Register />} />
              <Route path="visita/" element={<Purcharse />} />
              <Route path="encuesta/:traceid" element={<Survey />} />
            </Route>
          </Route>
        )}

        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default IndexRoutes;
