/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "store";

interface CampaignState {
  loading: boolean;
  completedSends: boolean;
  campaignId: string;
  saveCount: number;
  sendAfter: number;
}

export const initialState: CampaignState = {
  loading: false,
  completedSends: false,
  campaignId: "",
  saveCount: 0,
  sendAfter: 0,
};

export const campaignSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    SetcampaignId: (state, action: PayloadAction<string>) => {
      state = {
        ...state, 
        campaignId: action.payload,
      };
      return state
    },
    SetSendAfter: (state, action: PayloadAction<number>) => {
      state = {
        ...state, 
        saveCount : 0,
        sendAfter : action.payload,
      };
      return state
    },
    incrementSaveCount:(state)=> {
      state = {
        ...state, 
        saveCount : state.saveCount + 1,
        completedSends : state.saveCount + 1 === state.sendAfter ? true : state.completedSends,
      };
      return state
    },
    resetCampaign: () => initialState,
  },
  extraReducers: {},
});

export const {
  incrementSaveCount,
  SetcampaignId,
  SetSendAfter,
  resetCampaign,
} = campaignSlice.actions;
export const isLoading = (state: RootState) => state.campaigns.loading;
export const isComplete = (state: RootState) => state.campaigns.completedSends;
export const campaignId = (state: RootState) => state.campaigns.campaignId;
export const numberSends = (state: RootState) => state.campaigns.sendAfter;

export default campaignSlice.reducer;
