import { useEffect } from "react";
import { SideBar } from "components";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAccessToken, selectCurrentUser } from "../../features/auth/authSlice";
import { logoutUser } from "../../features/auth/authThunks";
import { useAppDispatch } from "../../store";

export const DashLayout = () => {
  const token = useSelector(selectAccessToken);
  const location = useLocation();
  
  const user = useSelector(selectCurrentUser);
  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(logoutUser());
  };

  useEffect(() => {
    if (token) {
      document.documentElement.classList.add('has-aside-left', 'has-aside-mobile-transition', 'has-navbar-fixed-top', 'has-aside-expanded');
    }
  }, []);
  
  return token ? (
    <>
      <nav id="navbar-main" className="navbar is-fixed-top">
        <div className="navbar-brand">
          <a
            className="navbar-item is-hidden-desktop jb-aside-mobile-toggle"
            href="#/">
            <span className="icon">
              <i className="mdi mdi-forwardburger mdi-24px" />
            </span>
          </a>
          <div className="navbar-item has-control">
            <div className="control" style={{"display":"none"}}>
              <input placeholder="Search everywhere..." className="input" />
            </div>
          </div>
        </div>
        <div className="navbar-brand is-right">
          <a
            className="navbar-item is-hidden-desktop jb-navbar-menu-toggle"
            data-target="navbar-menu"
            href="#/">
            <span className="icon">
              <i className="mdi mdi-dots-vertical" />
            </span>
          </a>
        </div>
        <div className="navbar-menu fadeIn animated faster" id="navbar-menu">
          <div className="navbar-end">

            <div className="navbar-item has-dropdown has-dropdown-with-icons has-divider has-user-avatar is-hoverable">
              <a className="navbar-link is-arrowless" href="#/">
                <div className="is-user-avatar">
                  <img
                    src="https://api.dicebear.com/7.x/personas/svg?seed=Felix"
                    alt="John Doe"
                  />
                </div>
                <div className="is-user-name">
                  <span>{user}</span>
                </div>
                <span className="icon">
                  <i className="mdi mdi-chevron-down" />
                </span>
              </a>
              <div className="navbar-dropdown" style={{"display":"none"}}>
                <a href="!#" className="navbar-item">
                  <span className="icon">
                    <i className="mdi mdi-account" />
                  </span>
                  <span>My Profile</span>
                </a>
                <a className="navbar-item" href="!#">
                  <span className="icon">
                    <i className="mdi mdi-settings" />
                  </span>
                  <span>Settings</span>
                </a>
                <a className="navbar-item" href="!#">
                  <span className="icon">
                    <i className="mdi mdi-email" />
                  </span>
                  <span>Messages</span>
                </a>
                <hr className="navbar-divider" />
                <a className="navbar-item" href="!#">
                  <span className="icon">
                    <i className="mdi mdi-logout" />
                  </span>
                  <span>Log Out</span>
                </a>
              </div>
            </div>
            <a
              href="#/"
              title="About"
              className="navbar-item has-divider is-desktop-icon-only">
              <span className="icon">
              <i className="fa-solid fa-info"/>
              </span>
              <span>About</span>
            </a>
            <a
              onClick={()=>logout}
              title="Log out"
              className="navbar-item is-desktop-icon-only"
              href="/#">
              <span className="icon">
              <i className="fa-solid fa-arrow-right-from-bracket"/>
              </span>
              <span>Log out</span>
            </a>
          </div>
        </div>
      </nav>
      <SideBar />
      <div className="container-kuovadi vh-100 overflow-auto">
        <div className="content flex-column vh-100 overflow-auto">
          <Outlet />
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default DashLayout;
