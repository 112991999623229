import { Link } from "react-router-dom";
import astronaut from "../../assets/img/astronaut.svg";
import planet from "../../assets/img/planet.svg";
import "./notfound.scss";

const NotFoundPage = () => (
  <div className="permission_denied">
    <div id="tsparticles" />
    <div className="denied__wrapper">
      <h1>404</h1>
      <h3>
        PERDIDO EN EL <span>ESPACIO</span> de Kuvadi? Hmm, parece que esa pagina
        no existe.
      </h3>
      <img id="astronaut" src={astronaut} alt="astronaut" />
      <img id="planet" src={planet} alt="planet" />
      <a href="!#">
        <Link to="/" className="denied__link">
          Inicio
        </Link>
      </a>
    </div>
  </div>
);

export default NotFoundPage;
