/* eslint-disable react/jsx-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable arrow-body-style */
import { Accordion, useModal } from "components/Global";
import { Sends } from "../../interfaces/definition";
import SendTable from "./SendTable";



interface MyModalProps {
  tittle: string;
  data: Sends[];
  remove: (sendId: number) => void;
}

const CenteredModal = (props: MyModalProps) => {
  const { isShown, closeModal } = useModal();
  
  return (
    <div id="sample-modal" className={`modal ${isShown ? 'is-active' : ''}`}>
        <div className="modal-background jb-modal-close"/>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title"> {props.tittle}</p>
            <button type="button" className="delete jb-modal-close" aria-label="close" onClick={()=>closeModal()}/>
          </header>
          <section className="modal-card-body">
          {props.data &&
            props.data.map((item, index) => (
              <Accordion
                key={`${index + 1}-view`}
                title={`Envio # ${(index + 1).toString()}: ${item.name}`}>
                  
                  <nav className="level">
                    <div className="level-left" >
                      <div className="level-item" style={{display:"none"}}/>
                      </div>
                    <div className="level-right">
                      <p className="level-item">
                      <button
                        type="button"
                        className="button is-danger is-inverted"
                        onClick={() => props.remove(item.id)}>
                          Eliminar
                          <span className="icon is-small">
                          <i className="fa-solid fa-trash-can"/>
                          </span>
                        
                      </button>
                      </p>
                    </div>
                  </nav>
                    <SendTable send={item} />
              </Accordion>
            ))}
          </section>
        </div>
        <button type="button" className="modal-close is-large jb-modal-close" onClick={()=>closeModal()} aria-label="close"/>
     </div>
  );
};

export default CenteredModal;
