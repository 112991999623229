import { useState } from "react";

export const useModal = () => {
  const [isShown, setIsShown] = useState<boolean>(false);
  const toggle = () => setIsShown(!isShown);
  
  const openModal = (modalTarget: string) => {
    const modal = document.getElementById(modalTarget);
    if (modal) {
      modal.classList.add('is-active');
      document.documentElement.classList.add('is-clipped');
    }
  };

  const closeModal = () => {
    const modal = document.querySelector('.modal.is-active');
    if (modal) {
      modal.classList.remove('is-active');
      document.documentElement.classList.remove('is-clipped');
    }
  };

  return {
    isShown,
    toggle,
    openModal,
    closeModal,
  };
};
