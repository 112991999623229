import React from "react";
import { createRoot } from "react-dom/client";
import "./scss/main.scss";
import { Provider } from "react-redux";
import { store } from "store";
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import client from "api/apollo-client";
import IndexRoutes from "./routes/IndexRoutes";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container!); // createRoot(container!) if you use TypeScript

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <IndexRoutes />
        </BrowserRouter>
      </ApolloProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
