import { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import moment from "moment";
import { useQuery } from "@apollo/client";
import { GET_PROGRAMMING_ALL } from "api/queries";
import { showLoading } from "components";
import { useAppDispatch } from "store";
import { removePrograming } from "features/sends/sendThunks";
import { IReponse } from "interfaces/definition";

const ProgrammingList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const dispatch = useAppDispatch();

  const {
    loading,
    error,
    refetch,
    data: programmingList = [],
  } = useQuery(GET_PROGRAMMING_ALL, {
    fetchPolicy: "no-cache",
    variables: {
      pagination: {
        page,
        limit: 20,
      },
    },
  });

  if (loading) {
    return <div>Cargando</div>;
  }

  if (error) {
    return <div>Something went wrong </div>;
  }

  const editProgramming = (programming_id: string) => {
    navigate('/admin/sends/schedule', { state: { programming_id } });
  };

  const removeProgramming = (identifier: string) => {
    confirmAlert({
      title: "Confirmación",
      message: "¿Esta seguro de eliminar",
      buttons: [
        {
          label: "Si",
          onClick: () => {
            const hideLoading = showLoading();
            dispatch(removePrograming(identifier))
              .then((response) => {
                const { message } = response.payload as IReponse;
                toast.success(message);
              })
              .catch((errorr) => {
                toast.error(`un error ha ocurrido ${errorr}`);
              })
              .finally(() => {
                hideLoading();
                refetch()
              });
          },
        },
        {
          label: "No",
          onClick: () => toast("No se realizaron cambios"),
        },
      ],
    });
  };


  return (
    <>
      <section className="section is-title-bar">
        <div className="level">
          <div className="level-left">
            <div className="level-item">
              <ul>
                <li>Envios</li>
                <li>Lista de programaciones</li>
              </ul>
            </div>
          </div>
          <div className="level-right">
            <div className="level-item" />
          </div>
        </div>
      </section>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Programaciones</h1>
              </div>
            </div>
            <div className="level-right" style={{ display: "none" }}>
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="fa-solid fa-list" />
              </span>
              Programaciones activas y pasadas
            </p>
          </header>
          <div className="card-content">
            <div className="b-table has-pagination">
              <div className="table-wrapper has-mobile-cards">
                <table className="table is-fullwidth is-striped is-hoverable is-fullwidth">
                  <thead>
                    <tr>
                      <th aria-label="Programaciones" />
                      <th>Nombre</th>
                      <th>Inicio</th>
                      <th>Repite</th>
                      <th>Estado</th>
                      <th>Creado</th>
                    </tr>
                  </thead>
                  <tbody>
                    {programmingList.Programming.map((scheduling, index) => (
                      <tr key={`${index + 1}`}>
                        <td>
                          <div>
                            <div className="dropdown is-hoverable">
                              <div className="dropdown-trigger">
                                <button
                                  className="button"
                                  type="button"
                                  aria-haspopup="true"
                                  aria-controls="dropdown-menu">
                                  <span className="icon is-small">
                                    <i
                                      className="fa-solid fa-bars"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </button>
                              </div>
                              <div
                                className="dropdown-menu"
                                id="dropdown-menu"
                                role="menu">
                                <div className="dropdown-content">
                                  <a
                                    className="dropdown-item"
                                    href="#/"
                                    onClick={() =>
                                      editProgramming(scheduling.identifier)
                                    }>
                                    Editar
                                  </a>
                                  <a className="dropdown-item" href="#/" onClick={() => removeProgramming(scheduling.identifier)}>
                                    Eliminar
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{scheduling.name}</td>
                        <td>{moment(scheduling.date_start).format("LL")}</td>
                        <td>
                          {scheduling.repeat_sending ? (
                            <span className="icon">
                              <i className="fa-solid fa-circle-check" />
                            </span>
                          ) : (
                            <span className="icon">
                              <i className="fa-solid fa-ban" />
                            </span>
                          )}
                        </td>
                        <td>
                          {scheduling.active ? (
                            <span className="icon has-text-success">
                              <i className="fa-regular fa-calendar-check" />
                            </span>
                          ) : (
                            <span className="icon has-text-danger">
                              <i className="fa-solid fa-calendar-xmark" />
                            </span>
                          )}
                        </td>
                        <td>{moment(scheduling.create_at).format("LL")}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="notification">
                  <div className="level">
                    <div className="level-left">
                      <div className="level-item">
                        <button
                          type="button"
                          disabled={page === 1}
                          onClick={() => setPage((prev) => prev - 1)}
                          className="button">
                          <i className="fa-solid fa-chevron-left" />
                        </button>
                        <button
                          type="button"
                          className="button"
                          disabled={programmingList.Programming.length === 0}
                          onClick={() => setPage((prev) => prev + 1)}>
                          <i className="fa-solid fa-chevron-right" />
                        </button>
                      </div>
                    </div>
                    <div className="level-right">
                      <div className="level-item">
                        <small>
                          Pagina {page}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProgrammingList;
