import { useEffect, useRef, useState } from "react";

const Accordion = ({ title, children }) => {
  const [expanded, setExpanded] = useState(false);
  const [height, setHeight] = useState("0px");
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(expanded ? `${contentRef.current.scrollHeight}px` : "0px");
    }
  }, [expanded]);

  const toggleCardState = () => {
    setExpanded(!expanded);
  };

  return (
    <div className={`card ${expanded ? 'expanded' : 'not-expanded'}`}>
      <header className="card-header" onClick={toggleCardState}>
        <p className="card-header-title">{title}</p>
        <button type="button" className="card-header-icon">
          <span className="icon">
            <i className={`fa ${expanded ? "fa-angle-up" : "fa-angle-down"}`} />
          </span>
        </button>
      </header>
      <div
        className="card-content"
        style={{height, overflow: 'hidden', transition: 'max-height 0.5s ease-in-out' }} ref={contentRef}>
            {children}
      </div>
    </div>
  );
};

export default Accordion;
