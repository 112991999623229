import { useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { InferType } from "yup";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { ApiErrorResponse, LoginCredentials } from "interfaces/definition";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "schemas/formSchema";
import "./login.scss";
import { useLoginMutation } from "api/apiSlice";
import { setCredentials } from "features/auth/authSlice";
import { Footer, showLoading } from "../../components";
import kuvadi from "../../assets/img/kuvadi.png";
import todos from "../../assets/img/todos.png";
import { useAppDispatch } from "../../store";

type LoginInput = InferType<typeof loginSchema>;

const LoginPage = () => {
  const captchaKey = process.env.REACT_APP_CAPTCHA_SITE_KEY as string;
  const captchaRef = useRef<ReCAPTCHA>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [login, { isLoading }] = useLoginMutation();

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInput>({
    resolver: yupResolver(loginSchema),
  });

  const onSubmit: SubmitHandler<LoginInput> = async (values) => {
    const hideLoading = showLoading();
    const captchaToken = await captchaRef.current.executeAsync();
    // eslint-disable-next-line no-param-reassign
    values.g_recaptcha_response = captchaToken;

    try {
      const userData = await login({
        username: values.username,
        password: values.password,
        g_recaptcha_response: values.g_recaptcha_response,
      } as LoginCredentials).unwrap();

      dispatch(setCredentials({ ...userData }));
      navigate("/admin");
    } catch (error) {
      const customError = error as unknown as ApiErrorResponse;

      if (!customError.data.message) {
        toast.error("Sin Respuesta del servidor");
      } else if (customError.status === 400) {
        toast.error("Usuario o contraseña no validos");
      } else if (customError.status === 401) {
        toast.error(customError.data.message);
      } else {
        toast.error("Fallo en el login");
      }
      reset();
    }

    hideLoading();
  };

  return (
    <div className="hero is-fullheight is-fluid has-text-centered kvd-bg">
      <img
        className="login-logo m-4"
        src={kuvadi}
        alt="conozca_logo"
        width={300}
      />
      <nav className="level">
        <div className="level-item">
          <img src={todos} alt="Todos conozca" width={512} />
        </div>
        <div className="level-item">
          <div className="box login-form">
            <form
              className="m-5"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="nope">
              <h1 className="is-size-6 mb-4 has-text-link">Iniciar Session</h1>
              <div className="field">
                <label className="label is-size-4 has-text-weight-light">Usuario</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="userInput"
                    placeholder="Usuario"
                    type="text"
                    className={`input is-rounded ${
                      errors.username?.type === "required" && "is-danger"
                    }`}
                    {...register("username", { required: true })}
                    autoComplete="off"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </div>
                {errors.username?.type === "required" && (
                  <p className="help is-danger">Datos no validos</p>
                )}
              </div>
              <div className="field">
                <label className="label is-size-4 has-text-weight-light">Contraseña</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    id="passwordInput"
                    placeholder="Contraseña"
                    type="password"
                    autoComplete="on"
                    className={`input is-rounded ${
                      errors.password?.type === "required" && "is-danger"
                    }`}
                    {...register("password", { required: true })}
                    autoSave="off"
                  />
                  <span className="icon is-small is-left">
                    <i className="fas fa-key" />
                  </span>
                </div>
                {errors.password?.type === "required" && (
                  <p className="help is-danger">Datos no validos</p>
                )}
              </div>
              <div className="my-3">
                <Link to="/forgot" className="is-link">
                  Olvido la contraseña?
                </Link>
              </div>
              <button type="submit" className="button is-link">
                Ingresar
              </button>
            </form>
          </div>
        </div>
      </nav>
      <ReCAPTCHA sitekey={captchaKey} ref={captchaRef} size="invisible" />
      <Footer />
    </div>
  );
};

export default LoginPage;
