import { Outlet } from "react-router-dom";
import "./apps.scss";

const AppsLayout = () => (
        <section className="container">
            <div className="columns is-multiline">
                <div className="column is-8 is-offset-2 register">
                        <Outlet />
                </div>
                <div className="column is-8 is-offset-2">
                    <br />
                    <nav className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <span className="icon">
                                    <i className="fab fa-twitter" />
                                </span> &emsp;
                                <span className="icon">
                                    <i className="fab fa-facebook" />
                                </span> &emsp;
                                <span className="icon">
                                    <i className="fab fa-instagram" />
                                </span> &emsp;
                                <span className="icon">
                                    <i className="fa-solid fa-envelope"/>
                                </span>
                            </div>
                        </div>
                        <div className="level-right">
                            <small className="level-item" style={{ color: "var(--textLight)" }}>
                                &copy; Kuvadi. All Rights Reserved.
                            </small>
                        </div>
                    </nav>
                </div>
            </div>
        </section>
    )

export default AppsLayout;
