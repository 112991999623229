import { useEffect, useRef, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import EmailEditor, { UnlayerOptions } from "react-email-editor";
import { useAppDispatch } from "store";
import { showLoading } from "components";
import "./newsletter.scss";
import { useSaveNewsletterMutation } from "api/apiSlice";
import { getNewsletterById } from "features/sends/sendThunks";
import {
  ApiErrorResponse,
  IReponse,
  Newsletter,
} from "../../interfaces/definition";


type FormNewsletter = {
  name: string;
  approve: boolean;
};

type props = {
  newsletterID: number;
  isClone: boolean;
};

const NewsletterPage = () => {
  const dispactch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const emailEditorRef = useRef<any>(null);
  const [emailId, setEmailId] = useState<string>();
  const [SaveNewsletter] = useSaveNewsletterMutation();

  const editorOptions: UnlayerOptions = {
    id: "editor",
    displayMode: "email",
    user: {
      id: 96454,
    },
    appearance: {
      theme: "light",
    },
    features: {
      textEditor: {
        emojis: true,
        spellChecker: true,
      },
      ai: true,
    },
    mergeTags: [
      {
        name: "Nombre Completo",
        value: "{{name}}",
        sample: "Sergio Andres Hernandez Rodriguez",
      },
      {
        name: "Genero",
        value: "{{genre}}",
        sample: "Masculino",
      },
      {
        name: "Almacen Preferido",
        value: "{{warehouse}}",
        sample: "ALMACEN PLAZA CENTRAL",
      },
      {
        name: "Dia Preferido",
        value: "{{day}}",
        sample: "12",
      },
      {
        name: "canal Preferido",
        value: "{{channel}}",
        sample: "WEB",
      },
      {
        name: "Producto Preferido",
        value: "{{preferred_product}}",
        sample: "T-SHIRT",
      },
      {
        name: "Ciudad Preferido",
        value: "{{city}}",
        sample: "Pereira plaza",
      },
      {
        name: "Promocion",
        value: "{{promo}}",
        sample: "Cliente de descuento entre 11% y 20%",
      },
      {
        name: "Afinidad",
        value: "{{affinity}}",
        sample: "AFINIDAD ALTA",
      },
      {
        name: "Segmento",
        value: "{{segment}}",
        sample: "TOP",
      },
      {
        name: "Proximo Inactivar",
        value: "{{close_Inactivation}}",
        sample: "1",
      },
      {
        name: "Alerta Deserción",
        value: "{{dropout_alert}}",
        sample: "CRECIMIENTO",
      },
      {
        name: "Cuadrante",
        value: "{{quadrant}}",
        sample: "frecuentes",
      },
      {
        name: "Subcuadrante",
        value: "{{subquadrant}}",
        sample: "top1",
      },
      {
        name: "Factor Grupo",
        value: "{{group_factor}}",
        sample: "TOP + BOTTON",
      },
      {
        name: "Probabilidad Upselling",
        value: "{{probability_upselling}}",
        sample: "MEDIA",
      },
      {
        name: "Probabilidad Crosselling",
        value: "{{probability_crosselling}}",
        sample: "MEDIO",
      },
      {
        name: "Producto Upselling",
        value: "{{producto_u}}",
        sample: "BOTTOM_FEMALE",
      },
      {
        name: "Producto Crosselling",
        value: "{{producto_cross}}",
        sample: "TRUNK_BOY",
      },
      {
        name: "Referencia Recomendada",
        value: "{{recommended_reference}}",
        sample: "AN2002222-1",
      },
      {
        name: "Producto mas comprado",
        value: "{{most_purchased_item}}",
        sample: "GORRO_FEMALE",
      },
      {
        name: "Ciclo de vida",
        value: "{{life_cycle}}",
        sample: "RETENER",
      },
      {
        name: "Ciclo de vida retener",
        value: "{{life_cycle_retention}}",
        sample: "RETENER: MANTENER",
      }
    ],
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormNewsletter>({
    defaultValues: {
      approve: false,
    },
  });

  useEffect(() => {
    if (location.state !== null) {
      const { newsletterID, isClone } = location.state as unknown as props;
      dispactch(getNewsletterById(newsletterID))
        .then((response) => {
          const { templateJson, uuid, name } = response.payload as Newsletter;
          if (templateJson != null) {
            emailEditorRef.current.editor.loadDesign(JSON.parse(templateJson));
            if (isClone === false) {
              setValue("name", name);
              setEmailId(uuid);
            }
          }
        })
        .catch((err) => {
          toast.warn(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmitNewsletter: SubmitHandler<FormNewsletter> = async (
    dataForm
  ) => {
    const hideLoading = showLoading();
    await emailEditorRef.current.editor.exportHtml((templateData) => {
      const { design, html } = templateData;
      const newsletterRegister: Newsletter = {
        uuid: emailId!,
        name: dataForm.name,
        approve: dataForm.approve,
        templateJson: JSON.stringify(design),
        templateHtml: html,
      };

      try {
        const resultData = SaveNewsletter(newsletterRegister).unwrap();

        const { message } = resultData as unknown as IReponse;
        toast(message);
        hideLoading();
        navigate("/admin/newsletter");
      } catch (err) {
        const customError = err as unknown as ApiErrorResponse;

        if (!customError.data.message) {
          toast.error("Sin Respuesta del servidor");
        } else if (customError.status === 400) {
          toast.error("Usuario o contraseña no validos");
        } else if (customError.status === 401) {
          toast.error("Usuario no autorizado");
        } else {
          toast.error("Fallo en el login");
        }
      }
    });
    hideLoading();
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <h1 className="title">Editor</h1>
              </div>
            </div>
            <div className="level-right is-fullwidth">
              <div className="level-item" />
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section ">
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-ballot" />
              </span>
              Nueva plantilla
            </p>
          </header>
          <div className="card-content container-email-editor">
            <EmailEditor ref={emailEditorRef} options={editorOptions} />
            <form onSubmit={handleSubmit(onSubmitNewsletter)} className="mt-5">
              <div className="field is-horizontal">
                <div className="field-label is-normal">
                  <label className="label">Nombre</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        className={`input is-fullwidth ${errors.name ? "is-danger" : ""}`}
                        type="text"
                        placeholder="Nombre de la plantilla"
                        aria-label="Nombre de la plantilla"
                        {...register("name", {
                          required: true,
                          min: 10,
                        })}
                      />
                    </p>
                  </div>
                  <div className="field">
                    <div className="control is-fullwidth">
                      <label className="checkbox">
                        <input
                          type="checkbox"
                          id="btn-check-outlined "
                          autoComplete="off"
                          {...register("approve")}
                        />
                        Aprobar
                      </label>
                    </div>
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-success">
                        Guardar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
       
      </section>
    </>
  );
};

export default NewsletterPage;
