import { useForm, Controller, SubmitHandler } from "react-hook-form";
import DatePicker from "react-datepicker";
import { SendsSchema } from "schemas/formSchema";
import { InferType } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

type SendInput = InferType<typeof SendsSchema>;

const ProfileForm = ({
  submitForm,
  getGeographicalHierarchy,
  getComercialHierarchy,
  getProductsForDiscounts,
  getRewards,
  getSendBeforeCampaign,
  dataObjetive,
  dataCustomerFocusList,
  dataCustomerCategory,
  dataAgeCategory,
  dataBirthdayMonth,
  dataGeographicalHierarchy,
  dataGeoHierarchyOption,
  dataComercialHierarchy,
  dataComercialHierarchyOption,
  dataPaymentMethods,
  dataConditionsMetrics,
  dataDiscountsRates,
  dataProductsForDiscounts,
  dataRewardsType,
  dataProductsForRewards,
  dataCustomerType,
  dataCampaings,
  dataSends,
  loading,
}): JSX.Element => {
  const [objetiveList, setObjetiveList] = useState(dataObjetive ?? []);
  const [customerFocusList, setCustomerFocusList] = useState(
    dataCustomerFocusList ?? []
  );
  const [customerCategoryList, setCustomerCategoryList] = useState(
    dataCustomerCategory ?? []
  );
  const [ageCategoryList, setAgeCategory] = useState(dataAgeCategory ?? []);
  const [birthdayMonthList, setBirthdayMonth] = useState(
    dataBirthdayMonth ?? []
  );
  const [geographicalHierarchyList, setGeographicalHierarchy] = useState(
    dataGeographicalHierarchy ?? []
  );
  const [geoHierarchyOption, setgeoHierarchyOption] = useState(
    dataGeoHierarchyOption ?? []
  );
  const [comercialHierarchy, setComercialHierarchy] = useState(
    dataComercialHierarchy ?? []
  );
  const [comercialHierarchyOption, setComercialHierarchyOption] = useState(
    dataComercialHierarchyOption ?? []
  );
  const [paymentMethods, setPaymentMethods] = useState(
    dataPaymentMethods ?? []
  );
  const [conditionsMetrics, setConditionsMetrics] = useState(
    dataConditionsMetrics ?? []
  );
  const [discountsRates, setDiscountsRates] = useState(
    dataDiscountsRates ?? []
  );
  const [productsForDiscounts, setProductsForDiscounts] = useState(
    dataProductsForDiscounts ?? []
  );
  const [rewardsType, setRewardsType] = useState(dataRewardsType ?? []);
  const [rewardsProducts, setRewardsProducts] = useState(
    dataProductsForRewards ?? []
  );
  const [customerType, setCustomerType] = useState(dataCustomerType ?? []);
  const [campaings, setCampaings] = useState(dataCampaings ?? []);
  const [sends, setSends] = useState(dataSends ?? []);

  /** constantantes para eventos */
  const [showHidenCheckPM, setShowHidenCheckPM] = useState(false);
  const [showHidenDatePicker, setShowHidenDatePicker] = useState(false);
  const [showHidenValDesc, setShowHidenValDesc] = useState(false);
  const [showHidenCP, setShowHidenCP] = useState(false);
  const [showHideUP, setshowHideUP] = useState(false);
  const [showHideBono, setshowHideBono] = useState(false);
  const [showHidenTC, setshowHidenTC] = useState<boolean>(false);
  const [fileName, setFileName] = useState("el archivo seleccionado aparecera aqui");

  const {
    register,
    resetField,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<SendInput>({
    resolver: yupResolver(SendsSchema),
  });

  useEffect(()=>{
    setSends(dataSends)
  },[dataSends])
  useEffect(() => {
    setComercialHierarchyOption(dataComercialHierarchyOption);
  }, [dataComercialHierarchyOption]);

  useEffect(() => {
    setRewardsProducts(dataProductsForRewards);
  }, [dataProductsForRewards]);

  useEffect(()=>{
    setProductsForDiscounts(dataProductsForDiscounts)
  },[dataProductsForDiscounts])

  useEffect(() => {
    setgeoHierarchyOption(dataGeoHierarchyOption);
  }, [dataGeoHierarchyOption]);

  const hidenAll = () => {
    setShowHidenDatePicker(false);
    setShowHidenValDesc(false);
    setShowHidenCP(false);
  };

  const hidenRewards = () => {
    setshowHideUP(false);
    setshowHideBono(false);
  };

  const handleTypeRewardsChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = event.target.value;

    resetField("value_reward");
    switch (selectedOption) {
      case "UNIDADDEPRODUCTO":
        hidenRewards();
        setshowHideUP(!showHideUP);
        getRewards();
        break;
      case "VALORENEFECTIVO":
        hidenRewards();
        setshowHideBono(true);
        break;
      case "BONODEREGALO":
        hidenRewards();
        setshowHideBono(true);
        break;
      default:
        hidenRewards();
    }
  };

  const handleTypeDiscountChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = event.target.value;
    resetField("next_campaing" && "value_discont");
    hidenAll();
    switch (selectedOption) {
      case "BONORECOMPRA":
        setShowHidenDatePicker(!showHidenDatePicker);
        break;
      case "PRECIOFIJO":
        setShowHidenValDesc(!showHidenValDesc);
        break;
      case "PORCENTAJEDEDESCUENTO":
        setShowHidenValDesc(!showHidenValDesc);
        break;
      case "C.C.ALACOMPRADEOTROSPRODUCTOS":
        getProductsForDiscounts();
        setShowHidenCP(!showHidenCP);
        break;
      default:
        hidenAll();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = (event.target as HTMLInputElement)?.files;

    if (files && files.length === 1) {
      const uploadedFile = files[0];
      setFileName(uploadedFile.name);
    }
  };

  const handleSelectChangeGeoHierarchy = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = event.target.value;
    if (selectedOption !== "nacional") {
      getGeographicalHierarchy(selectedOption);
    }
    resetField("jegeooptions");
  };

  const handleSelectChangeComercialHierarchy = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedOption = event.target.value;
    if (selectedOption !== "todos") {
      getComercialHierarchy(selectedOption);
    }
    resetField("jcoptions");
  };

  const handleSelectCampaign=(event: React.ChangeEvent<HTMLSelectElement>)=>{
    const selectedOption = event.target.value;
    getSendBeforeCampaign(selectedOption)
  }

  const clearForm=()=>{
    reset()
    setProductsForDiscounts([])
    setRewardsProducts([])
    setSends([])
    setgeoHierarchyOption([])
    setComercialHierarchyOption([])
    setFileName("el archivo seleccionado aparecera aqui")
  }

  const onSubmit: SubmitHandler<SendInput> = (datos) => {
    submitForm(datos);
    window.scrollTo({ top: 0, behavior: "smooth" });
    clearForm()
  };

  return (
    <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="fa-brands fa-wpforms" />
                  </span>
                  Información Basica
                </p>
              </header>
              <div className="card-content">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Nombre del envio</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <input
                          autoComplete="off"
                          type="text"
                          className={`input ${errors.name && "is-danger"}`}
                          id="name"
                          {...register("name")}
                        />
                      </p>
                      <p className="help is-danger">{errors.name?.message}</p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Objetivo</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            className={`${errors.objetive && "is-danger"}`}
                            size={3}
                            id="objetive"
                            aria-describedby="objetive"
                            multiple
                            {...register("objetive")}>
                            {objetiveList.map((option) => (
                              <option
                                key={option.name.toLowerCase()}
                                value={option.name.toLowerCase()}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <p className="help is-danger">
                          {errors.objetive?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Foco</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            id="focus"
                            className={` ${
                              errors.customer_focus && "is-danger"
                            }`}
                            size={3}
                            aria-describedby="customer_focus"
                            multiple
                            {...register("customer_focus")}>
                            {customerFocusList.map((option) => (
                              <option
                                key={option.name.toLowerCase()}
                                value={option.name.toLowerCase()}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <p className="help is-danger">
                          {errors.customer_focus?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Prioridad de Contacto</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            className={errors.customer_category && "is-danger"}
                            size={3}
                            id="customer_category"
                            multiple
                            {...register("customer_category")}>
                            {customerCategoryList.map((option) => (
                              <option
                                key={option.value.toLowerCase()}
                                value={option.value.toLowerCase()}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <p className="help is-danger">
                          {errors.customer_category?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tile is-parent">
            <div className="card tile is-child">
              <header className="card-header">
                <p className="card-header-title">
                  <span className="icon">
                    <i className="fa-brands fa-wpforms" />
                  </span>
                  Filtros Basicos
                </p>
              </header>
              <div className="card-content">
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Rango de edad</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            id="age_category"
                            size={3}
                            aria-describedby="rango de edad"
                            multiple
                            {...register("age_category")}>
                            {ageCategoryList.map((option) => (
                              <option
                                key={option.value.toLowerCase()}
                                value={option.value.toLowerCase()}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                          <p className="help is-danger">
                            {errors.age_category?.message}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Genero</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            id="genero"
                            size={2}
                            aria-describedby="customer_focus"
                            multiple
                            {...register("genre")}>
                            <option value="M">Masculino</option>
                            <option value="F">Femenino</option>
                          </select>
                        </div>
                        <p className="help is-danger">
                          {errors.genre?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label"> Mes de cumpleaños</label>
                  </div>
                  <div className="field-body">
                    <div className="field is-narrow">
                      <div className="control">
                        <div className="select is-multiple is-fullwidth">
                          <select
                            id="birthday"
                            size={3}
                            aria-describedby="birthday"
                            multiple
                            {...register("birthday_month")}>
                            {birthdayMonthList.map((option) => (
                              <option
                                key={option.value.toLowerCase()}
                                value={option.value.toLowerCase()}>
                                {option.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <p className="help is-danger">
                          {errors.birthday_month?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Clientes que</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded">
                        <div className="select is-fullwidth">
                          <select
                            placeholder="Selccione una opción"
                            className="form-control"
                            id="customer_rank"
                            {...register("customer_rank")}>
                            <option value="">--Seleccione una opción--</option>
                            <option value="si">Si</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">Comprarón entre</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <p className="control is-expanded has-icons-left">
                        <Controller
                          name="sale_date_start"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              maxDate={new Date()}
                              dateFormat="yyyy/MM/dd"
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              className={`input ${
                                errors.sale_date_start && "is-danger"
                              }`}
                              autoComplete="off"
                              isClearable
                              withPortal
                            />
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                      </p>
                      <p className="help is-danger">{`${
                        errors.sale_date_start?.message !== undefined
                          ? errors.sale_date_start?.message
                          : ""
                      } `}</p>
                    </div>
                    <div className="field">
                      <p className="control is-expanded has-icons-left has-icons-right">
                        <Controller
                          name="sale_date_end"
                          control={control}
                          render={({ field }) => (
                            <DatePicker
                              maxDate={new Date()}
                              dateFormat="yyyy/MM/dd"
                              selected={field.value}
                              onChange={(date) => field.onChange(date)}
                              className={`input ${
                                errors.sale_date_start && "is-danger"
                              }`}
                              autoComplete="off"
                              isClearable
                              withPortal
                            />
                          )}
                        />
                        <span className="icon is-small is-left">
                          <i className="fa-solid fa-calendar-days" />
                        </span>
                      </p>
                      <p className="help is-danger">{`${
                        errors.sale_date_end?.message !== undefined
                          ? errors.sale_date_end?.message
                          : ""
                      } `}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="fa-brands fa-wpforms" />
              </span>
              Filtros Avanzados
            </p>
          </header>
          <div className="card-content">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"> Jerarquia Geografica</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="jegeografico"
                        {...register("jegeografico")}
                        onChange={(e) => handleSelectChangeGeoHierarchy(e)}>
                        <option value="">--Seleccione una opción--</option>
                        {geographicalHierarchyList.map((option) => (
                          <option
                            key={option.name.toLowerCase()}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="help is-danger">
                      {errors.jegeografico?.message}
                    </p>
                  </div>
                </div>
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-multiple is-fullwidth">
                      <select
                        className={` ${errors.jegeooptions && "is-danger"}`}
                        size={3}
                        aria-describedby="jegeooptions"
                        multiple
                        {...register("jegeooptions")}>
                        {geoHierarchyOption.map((option, index) => (
                          <option
                            key={`key${index + 1}l`}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="help is-danger">
                      {errors.jegeooptions?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label"> Jerarquia Comercial</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="jecomercial"
                        {...register("jecomercial")}
                        onChange={(e) =>
                          handleSelectChangeComercialHierarchy(e)
                        }>
                        <option key="defaultc" value="">
                          --Seleccione una opción--
                        </option>
                        {comercialHierarchy.map((option) => (
                          <option
                            key={option.name.toLowerCase()}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="help is-danger">
                      {errors.jecomercial?.message}
                    </p>
                  </div>
                </div>
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-multiple is-fullwidth">
                      <select
                        className={` ${errors.jcoptions && "is-danger"}`}
                        size={3}
                        aria-describedby="jcoptions"
                        multiple
                        {...register("jcoptions")}>
                        {comercialHierarchyOption.map((option) => (
                          <option
                            key={option.name.toLowerCase()}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="help is-danger">
                      {errors.jcoptions?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Medio de pago</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="payment_method"
                        {...register("payment_method")}
                        onChange={(e) => {
                          setShowHidenCheckPM(false);
                          if (e.target.value === "todos excepto") {
                            setShowHidenCheckPM(!showHidenCheckPM);
                          }
                        }}>
                        <option key="default" value="">
                          --Seleccione una opción--
                        </option>
                        {paymentMethods.map((option) => (
                          <option
                            key={option.id}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="help is-danger">
                      {errors.payment_method?.message}
                    </p>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    {showHidenCheckPM && (
                      <>
                        {paymentMethods.map((option, index) =>
                          option.name === "Todos" ||
                          option.name === "Todos Excepto" ? null : (
                            <label
                              className="checkbox mr-2"
                              key={`${index + 1}p`}>
                              <input
                                className={`mr-1 ${
                                  errors.payment_list && "is-danger"
                                }`}
                                type="checkbox"
                                id={option.name}
                                value={option.name}
                                {...register("payment_list")}
                              />
                              {option.name}
                            </label>
                          )
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Condiciones Metricas</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="condition_metrica"
                        {...register("condition_metrica")}>
                        <option key="defaultmc" value="">
                          --Seleccione una opción--
                        </option>
                        {conditionsMetrics.map((option) => (
                          <option
                            key={option.name.toLowerCase()}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className={` ${errors.operator_metrica && "is-danger"}`}
                        {...register("operator_metrica")}>
                        <option value="">--Seleccione una opción--</option>
                        <option value=">=">{">="}</option>
                        <option value="=">=</option>
                        <option value="<=">{"<="}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <p className="control is-expanded">
                    <input
                      type="text"
                      className={`input ${errors.value_metrica && "is-danger"}`}
                      placeholder="Valor o Cantidad"
                      {...register("value_metrica")}
                    />
                  </p>
                </div>
                <div className="field">
                  <div className="field is-grouped-multiline is-grouped">
                    <div className="control">
                      <label className="checkbox">
                        <input
                          className="checkbox"
                          type="checkbox"
                          role="switch"
                          value={1}
                          id="filter_condition_metric"
                          {...register("filter_condition_metric")}
                        />
                        <span className="check"> </span>
                        <span className="control-label">
                          Filtrar por este campo
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Tipos de Descuento</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="type_discount"
                        {...register("type_discount")}
                        onChange={(e) => {
                          handleTypeDiscountChange(e);
                        }}>
                        <option key="defaulttd" value="">
                          --Seleccione una opción--
                        </option>
                        {discountsRates.map((option, index) => (
                          <option
                            key={`${index + 1}d`}
                            value={option.name
                              .trim()
                              .toUpperCase()
                              .replace(/ /g, "")}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {showHidenDatePicker && (
                  <div className="field">
                    <p className="control is-expanded has-icons-left">
                      <Controller
                        name="next_campaing"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            id="next_campaing_id"
                            dateFormat="yyyy/MM/dd"
                            minDate={new Date()}
                            selected={field.value}
                            onChange={(date) => field.onChange(date)}
                            className={`input ${
                              errors.next_campaing && "is-invalid"
                            }`}
                            autoComplete="off"
                            isClearable
                            withPortal
                          />
                        )}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-solid fa-calendar-days" />
                      </span>
                    </p>
                  </div>
                )}
                {showHidenValDesc && (
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        id="value_discount"
                        type="text"
                        min={0}
                        className={`input ${
                          errors.value_discont && "is-invalid"
                        }`}
                        placeholder="Valor"
                        {...register("value_discont")}
                      />
                      <span className="icon is-small is-left">
                        <i className="mdi mdi-mail" />
                      </span>
                      <span className="icon is-small is-right">
                        <i className="mdi mdi-check" />
                      </span>
                    </p>
                  </div>
                )}
                {showHidenCP && (
                  <div className="field is-narrow">
                    <div className="control">
                      <div className="select is-multiple is-fullwidth">
                        <select
                          className={`form-select ${
                            errors.product_discount && "is-invalid"
                          }`}
                          size={3}
                          aria-describedby="product_discount"
                          multiple
                          {...register("product_discount")}>
                          {productsForDiscounts.map((option) => (
                            <option
                              key={option.name.toLowerCase()}
                              value={option.name.toLowerCase()}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
                <div className="field">
                  <div className="field is-grouped-multiline is-grouped">
                    <div className="control">
                      <label className="checkbox">
                        <input
                          className="checkbox"
                          type="checkbox"
                          role="switch"
                          value={1}
                          id="filter_type_discount"
                          {...register("filter_type_discount")}
                        />
                        <span className="check"> </span>
                        <span className="control-label">
                          Filtrar por este campo
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Tipo de Recompensa</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="type_reward"
                        {...register("type_reward")}
                        onChange={(e) => {
                          handleTypeRewardsChange(e);
                        }}>
                        <option key="defaultr" value="">
                          --Seleccione una opción--
                        </option>
                        {rewardsType.map((option) => (
                          <option
                            key={option.name.toLowerCase()}
                            value={option.name
                              .trim()
                              .toUpperCase()
                              .replace(/ /g, "")}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {showHideUP && (
                  <div className="field is-narrow">
                    <div className="control">
                      <div className="select is-multiple is-fullwidth">
                        <select
                          size={3}
                          aria-describedby="product_reward"
                          multiple
                          {...register("product_reward")}>
                          {rewardsProducts.map((option) => (
                            <option
                              key={option.name.toLowerCase()}
                              value={option.name.toLowerCase()}>
                              {option.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <p className="help is-danger">
                        {errors.product_reward?.message}
                      </p>
                    </div>
                  </div>
                )}
                {showHideBono && (
                  <div className="field">
                    <p className="control is-expanded">
                      <input
                        id="value_reward"
                        type="text"
                        className="input"
                        placeholder="$0"
                        {...register("value_reward")}
                      />
                    </p>

                    <p className="help is-danger">
                      {errors.value_reward?.message}
                    </p>
                  </div>
                )}
                <div className="field">
                  <div className="field is-grouped-multiline is-grouped">
                    <div className="control">
                      <label className="checkbox">
                        <input
                          className="checkbox"
                          type="checkbox"
                          role="switch"
                          value={1}
                          id="filter_type_reward"
                          {...register("filter_type_reward")}
                        />
                        <span className="check"> </span>
                        <span className="control-label">
                          Filtrar por este campo
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Tipo de cliente</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        id="type_customers"
                        {...register("type_customers")}
                        onChange={(e) => {
                          setshowHidenTC(false);
                          const { value } = e.target;
                          if (value === "todos excepto")
                            setshowHidenTC(!showHidenTC);
                        }}>
                        <option key="defaultcu" value="">
                          --Seleccione una opción--
                        </option>
                        {customerType.map((option) => (
                          <option
                            key={option.id}
                            value={option.name.toLowerCase()}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                {showHidenTC && (
                  <div className="field">
                    <div className="field is-grouped-multiline is-grouped">
                      <div className="control">
                        {customerType.map((option, index) =>
                          option.name === "Todos" ||
                          option.name === "Todos Excepto" ? null : (
                            <label
                              className="b-checkbox checkbox mr-2"
                              key={`${index + 1}pe`}>
                              <input
                                className={`checkbox mr-1 ${
                                  errors.value_customer && "is-danger"
                                }`}
                                value={option.name}
                                type="checkbox"
                                id={option.name}
                                {...register("value_customer")}
                              />
                              <span className="control-label">
                                {option.name}
                              </span>
                            </label>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Clientes a</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="file has-name is-fullwidth">
                    <label className="file-label">
                      <Controller
                        name="customer_file"
                        control={control}
                        defaultValue={null}
                        render={({ field: { onChange, onBlur, ref } }) => (
                          <input
                            type="file"
                            className={`file-input ${
                              errors.customer_file && "is-danger"
                            }`}
                            id="custome_file"
                            accept=".csv"
                            onChange={(event) => {
                              onChange(event.target.files);
                              handleFileUpload(event);
                            }}
                            onBlur={onBlur}
                            ref={ref}
                          />
                        )}
                      />
                      <span className="file-cta">
                        <span className="file-icon">
                          <i className="fas fa-upload" />
                        </span>
                        <span className="file-label">Seleccione un archivo</span>
                      </span>
                      <span className="file-name">{fileName}</span>
                    </label>
                  </div>

                  <p className={`help ${errors.customer_file_options ? `is-danger`:`is-info`}`}>
                    {errors.customer_file_options ? `seleccione si los clientes sera incluidos o excluidos` : `Cargue un archivo CVS con email o numero de contacto de los clientes.` }
                  </p>
  
                </div>
                <div className="field">
                  <div className="control">
                    <label className="radio mr-2">
                      <input
                        className={`mr-1 ${
                          errors.customer_file_options && "is-danger"
                        }`}
                        type="radio"
                        id="inlineRadioInclude"
                        value="incluir"
                        {...register("customer_file_options")}
                      />
                      <span className="control-label">Incluir</span>
                    </label>
                    <label className="radio mr-2">
                      <input
                        className={`mr-1 ${
                          errors.customer_file_options && "is-danger"
                        }`}
                        type="radio"
                        id="inlineRadioExclude"
                        value="excluir"
                        {...register("customer_file_options")}
                      />
                      <span className="control-label">Excluir</span>
                    </label>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <i className="fa-solid fa-download mr-2" />
                    <a href="https://cnz-resources.s3.amazonaws.com/demo.csv">
                      Archivo de muestra
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Clientes Anteriores</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-fullwidth">
                      <select
                        className="form-select"
                        id="previous_campaign"
                        {...register("previous_campaign_customers")}
                        onChange={(e) => {
                          handleSelectCampaign(e);
                        }}>
                        <option key="defaultpc" value="">
                          --Seleccione una opción--
                        </option>
                        {campaings.map((option) => (
                          <option key={option.identifier} value={option.identifier}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <p className={`help ${errors.previous_campaign_to ? `is-danger`:`is-info`}`}>
                    {errors.previous_campaign_to ? `seleccione si los clientes sera incluidos o excluidos` : ` Puede excluir on incluir los clientes por campaña o envio.` }
                  </p>
                </div>
                <div className="field is-narrow">
                  <div className="control">
                    <div className="select is-multiple is-fullwidth">
                      <select
                        id="previous_send"
                        size={3}
                        aria-describedby="previous_send"
                        multiple
                        {...register("previous_send", {
                          valueAsNumber: true,
                        })}>
                        {sends.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <label className="radio mr-2">
                      <input
                        className={`mr-1 ${
                          errors.previous_campaign_to && "is-danger"
                        }`}
                        type="radio"
                        id="previous_campaign_to"
                        value="incluir"
                        {...register("previous_campaign_to")}
                      />
                      <span className="control-label">Incluir</span>
                    </label>
                    <label className="radio mr-2">
                      <input
                        className={`mr-1 ${errors.previous_campaign_to && "is-invalid"}`}
                        type="radio"
                        id="previous_campaign_to2"
                        value="excluir"
                        {...register("previous_campaign_to")}
                      />
                      <span className="control-label">Excluir</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="field is-horizontal">
              <div className="field-label" />
              <div className="field-body">
                <div className="field">
                  <div className="field is-grouped">
                    <div className="control">
                      <button
                        disabled={loading}
                        type="submit"
                        className="button is-primary ">
                        <span>Guardar</span>
                      </button>
                    </div>
                    <div className="control">
                      <button
                        disabled={loading}
                        type="button"
                        className="button is-primary is-outlined"
                        onClick={() => {
                          clearForm();
                        }}>
                        Limpiar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </form>
  );
};
export default ProfileForm;
