import ReactDOM from "react-dom/client";
import { Loading } from ".";

const showLoading = () => {
  const loading = document.createElement("div");
  document.body.appendChild(loading);

  const root = ReactDOM.createRoot(loading);
  root.render(<Loading />);

  const hideLoading = () => {
    setTimeout(() => {
      root.unmount();
      loading.remove();
    }, 0);
  };

  return hideLoading;
};
export default showLoading;
